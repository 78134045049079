<template>

    <section>
        <div class="modal fade" id="modal-bayar">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Bayar</h6>
                        <button type="button" id="close-bayar" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body py-4">
                        <div class="row flex-nowrap mx-n1">
                            <template v-if="defaultPay == 'all' && tunai == 0">
                            <div class="col-4 px-1">
                                <button v-on:click="setChooseNominal()" class="btn btn-primary btn-block py-3" data-toggle="modal" data-target="#modal-bayar-cash" data-dismiss="modal">Cash</button>
                            </div>
                        </template>
                            <div class="col-4 px-1">
                                <button class="btn btn-success btn-block py-3" id="chooseEdc" data-toggle="modal" data-target="#modal-bayar-edc" data-dismiss="modal" v-on:click="pembulatan()">EDC</button>
                            </div>
                            <template v-if="lossBill == 'show' && tunai == 0">
                            <div class="col-4 px-1">
                                <button class="btn btn-danger btn-block py-3" 
                                v-on:click="confirmLostbill()"
                                data-dismiss="modal">LOSTBILL</button>
                            </div>
                            </template>
                            <!-- <div class="col-4 px-1">
                                <button class="btn btn-warning btn-block py-3" data-toggle="modal" data-target="#modal-bayar-multipayment" data-dismiss="modal">Multipayment</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-bayar-cash">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Cash</h6>
                        <button id="close-modal-bayar" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group text-center">
                            <h2 class="m-0 text-accent">Total Bayar : {{currency(roundedTotalBayar,'')}}</h2>
                            <h5 v-if="exchangeRounded > 0" class="m-0 text-accent" style="color: red;">Diskon -{{currency(exchangeRounded,'')}}</h5>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <label for="" class="col-form-label col-4">Pilih Bayar</label>
                            <div class="col-8">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label v-for="(nominal, index) in chooseNominal" :key="index" class="btn btn-secondary active">
                                        <input v-on:click="confirmNominal(nominal)" type="radio" name="cash-bayar">{{currency(nominal,'')}}
                                    </label>
                                </div>
                            </div>      
                        </div>
                        <div class="form-group row">
                            <label for="" class="col-form-label col-4">Perkiraan Bayar</label>
                            <div class="col-8">
                                <div class="btn-group btn-group-toggle mb-2" data-toggle="buttons">
                                    <label v-for="(nominal, index) in estimatedNominal" :key="index" class="btn btn-secondary active">
                                        <input v-on:click="confirmNominal(nominal)" type="radio" name="cash-bayar">{{currency(nominal,'')}}
                                    </label>
                                </div>
                            </div>      
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">Masukkan Jumlah Uang Pembayaran</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <!-- <input id="input_nominal_pay" type="text" class="form-control numeric" v-model="inputNominal" v-on:input="setInputNominal()"> -->
                                    <money3 v-model.number="inputNominal" v-bind="config" class="form-control" @keyup="setInputNominal"></money3>
                                    <!-- <input id="uang_fisik" type="text" class="form-control numeric"> -->
                                </div>
                                <template v-if="!inputNominalValid">
                                    <div class="col-auto px-1">
                                        <button class="btn btn-primary btn-confirm-cash" disabled>Cash</button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-auto px-1">
                                        <button v-on:click="confirmNominal(inputNominal)" class="btn btn-primary btn-confirm-cash">Cash</button>
                                        <!-- <button v-on:click="confirmCash()" class="btn btn-primary btn-confirm-cash">Cash</button> -->
                                    </div>
                                </template>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button id="show-modal-kembalian" data-toggle="modal" data-backdrop="static" data-target="#modal-kembalian"/>
        <div class="modal fade" id="modal-kembalian">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Kembalian</h6>
                        <!-- <button id="close-modal-exchange" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button> -->
                    </div>
                    <div class="modal-body py-4">
                        <div class="form-group text-right">
                            <h2 class="m-0 text-accent">{{currency(exchange,'')}}</h2>
                            <!-- <h6 v-if="exchangeRounded > 0" class="m-0 text-accent" style="color: red;">Diskon {{currency(exchangeRounded,'Rp')}}</h6> -->
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">Free Kembalian</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <!-- <input v-model="exchangeFreeNominal" id="free_kembalian" type="text" class="form-control numeric"> -->
                                    <money3 v-model.number="exchangeFreeNominal" v-bind="config" class="form-control"></money3>
                                </div>
                                <!-- <div class="col-auto px-1">
                                    <button v-on:click="pay(1)" class="btn btn-success btn-confirm-cash">Cetak</button>
                                </div> -->
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group">
                            <label for="">NIK/ID Member</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="member_id_poppay" 
                                    type="text" class="form-control numeric" 
                                    :value="memberId"
                                    @input="$emit('update:memberId', $event.target.value)"
                                    v-on:keyup="searchMember()">
                                    <!-- <input id="member_id_poppay" type="text" class="form-control numeric" v-model="memberIdCustomer" v-on:keyup="searchMember()"> -->
                                </div>
                                <button 
                                class="btn btn-accent"
                                type="button"
                                v-on:click="searchMember()"><i class="fa fa-search"></i>
                                </button>
                                <div class="col px-1">
                                    <input type="text" class="form-control numeric" :value="compMemberName" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Nomor HP</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="nomor_hp_bos" type="text" class="form-control numeric" placeholder="Contoh: 081765765243" :value="nomorHp" minLength="10">
                                </div>
                            </div>
                        </div>
                        <!-- <div style="text-align: center;">Proses....</div> -->
                    </div>
                    <div class="modal-footer">
                        <button id="close-modal-exchange" type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close" :disabled="buttonCloseStruk" v-on:click="gohome()">
                            Selesai
                        </button>
                      <button v-on:click="strukCash(1,0)" class="btn btn-success" :disabled="buttonStruk">Kirim WA</button>
                      <button v-on:click="strukCash(1,1)" class="btn btn-warning" :disabled="buttonStruk">Cetak & Kirim WA</button>
                      <button v-on:click="strukCash(0,1)" class="btn btn-accent" :disabled="buttonStruk">Cetak</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-bayar-edc">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">EDC</h6>
                        <button id="closeChooseEdc" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body py-4">
                        <div class="form-group text-center">
                            <h2 class="m-0 text-accent">Total Bayar : {{currency(total,'')}}</h2>
                            <!-- <h5 v-if="exchangeRounded > 0" class="m-0 text-accent" style="color: red;">Diskon -{{currency(exchangeRounded,'Rp')}}</h5> -->
                        </div>
                        <template v-if="defaultPay != 'all'">
                        <hr/>
                        <div class="form-group">
                            <div class="row mx-n1">
                                <!-- <div class="col px-1">
                                    <label for="status_selisih">Status Selisih</label>
                                    <select id = "status_selisih" :class="'form-control option'" 
                                      v-model="statusSelisih">
                                        <option value="kurang">Kurang</option>
                                        <option value="lebih">Lebih</option>
                                    </select>
                                </div> -->
                                <div class="col px-1">
                                    <label for="nominal_selisih">Nominal Selisih (Jika Ada)</label>
                                    <money3 v-model.integers="inputNominalSelisih" v-bind="configSelisih" class="form-control"></money3>
                                </div>
                            </div>
                        </div>
                        </template>
                        <label for="">Pilih Bank</label>
                        <div class="row mx-n1">
                            <template v-if="defaultPay != 'all'">
                                <template v-for="payment in paymentMethod"
                                :key="payment.m_payment_method_id">
                                    <template v-if="payment.m_payment_method_name == defaultPay">
                                        <div class="col-4 px-1 mb-2">
                                            <button class="btn btn-primary btn-block border-0 py-3" 
                                            :style="{'background': payment.m_payment_method_color}"
                                            v-on:click="pay(payment.m_payment_method_id)">
                                                {{capitalWord(payment.m_payment_method_name)}}
                                            </button>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="payment in paymentMethod"
                                :key="payment.m_payment_method_id">
                                    <template v-if="payment.m_payment_method_type == 'transfer'">
                                        <div class="col-4 px-1 mb-2">
                                            <button class="btn btn-primary btn-block border-0 py-3" 
                                            :style="{'background': payment.m_payment_method_color}"
                                            v-on:click="pay(payment.m_payment_method_id)">
                                                {{capitalWord(payment.m_payment_method_name)}}
                                            </button>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                        <hr>
                        <button data-toggle="modal" data-target="#modal-bayar" data-dismiss="modal" type="button" class="btn btn-light">Kembali</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-bayar-edc-lainnya">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Lainnya</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body py-4">
                        <div class="row mx-n1">
                            <div class="col-4 px-1 mb-2">
                                <button class="btn btn-primary btn-block border-0 py-3" style="background: #1A75D2;">KPP/ Voucher</button>
                            </div>
                            <div class="col-4 px-1 mb-2">
                                <button class="btn btn-primary btn-block border-0 py-3" style="background: #FB8D03;">Print Bill</button>
                            </div>
                            <div class="col-4 px-1 mb-2">
                                <button class="btn btn-primary btn-block border-0 py-3" style="background: #28B2AA;">Lost Bill</button>
                            </div>
                            <div class="col-4 px-1 mb-2">
                                <button class="btn btn-primary btn-block border-0 py-3" style="background: #9371DB;">Gratis</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-bayar-multipayment">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Multipayment</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body py-4">
                        <div class="form-group">
                            <label for="">Free Kembalian</label>
                            <div class="row mx-n1">
                                <div class="col-auto px-1">
                                    <select name="" id="" class="form-control">
                                        <option value="">Cash</option>
                                        <option value="">Mandiri</option>
                                        <option value="">BNI</option>
                                        <option value="">BRI</option>
                                        <option value="">BCA</option>
                                        <option value="">OVO</option>
                                        <option value="">Gopay</option>
                                    </select>
                                </div>
                                <div class="col px-1">
                                    <input type="text" class="form-control numeric" placeholder="Jumlah Nominal">
                                </div>
                                <div class="col-auto px-1">
                                    <button class="btn btn-primary btn-confirm-cash">Tambah</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script lang="js">
import {roundTo, roundToUp, roundToDown} from 'round-to';
import axios from 'axios';
// import { useStore } from 'vuex'
// import { useRouter, useRoute } from 'vue-router';
import '@formatjs/intl-numberformat';
import { Money3Component } from 'v-money3';
import {toRef, reactive, computed, ref} from 'vue';

    export default {
        name: "PopupPay",
        components: { 
            money3: Money3Component
        },
        props: [
            'total',
            'sub_total',
            'currentRound',
            'transId',
            'pajak',
            'service',
            'tunai',
            'discount',
            'defaultPay',
            'voucher',
            'lossBill',
            'nomorHp',
            'memberId',
            'splitPay'
        ],
        data(){
            return{
                chooseNominal: [],
                estimatedNominal: [],
                inputNominal: '',
                inputNominalValid: false,
                payNominal: 0,
                exchange: 0,
                exchangeFreeNominal: 0,
                paymentMethod: [],
                exchangeRounded: 0,
                rounded: 0,
                cashierRole: {},
                roundedTotalBayar: 0,
                config: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false
                },
                configSelisih: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false,
                    disableNegative: false,
                },
                buttonStruk: false,
                buttonCloseStruk: true,
                kirimWa: '',
                cetakStruk: 0,
                inputNominalSelisih: 0,
                statusSelisih: 'kurang',
                waroengInfo : {},
                currencyFormat : 'id-ID',
                currencyPrefix : 'IDR',
                memberIdCustomer : '',
                memberName: ''
            }
        },
        mounted() {
            this.loadPaymentMethod()
            axios.get('m-waroeng/waroeng-info')
            .then(resp => {
                this.waroengInfo = resp.data
                if(this.waroengInfo.currency == 'RM'){
                    this.currencyFormat = 'ms-MY'
                    this.currencyPrefix = 'MYR'

                    this.config.decimal = '.'
                    this.config.thousands = ','
                    this.config.prefix = 'RM '
                    this.config.precision = 2

                    this.configSelisih.decimal = '.'
                    this.configSelisih.thousands = ','
                    this.configSelisih.prefix = 'RM '
                    this.configSelisih.precision = 2
                }
            }).catch(err => console.log(err))
            // this.setupRole()
            // window.setTimeout( "loadingComponentOrder()" ,750);
            document.getElementById("listTableNavigation").classList.add("active");
        },
        // setup(props){
            // console.log("Member :",props.memberId)
            // const newMemberId = toRef(props, reactive('memberId'))
            // this.memberIdCustomer = JSON.parse(JSON.stringify(props.memberId))
            // const memberIdCustomer = toRef(props, 'memberId')
            
            // return { memberIdCustomer }
        //     const router = useRouter()
        //     const store = useStore()
        // },
        computed:{
            compMemberName(){
                return this.memberName
            }
        },
        methods: {
            searchMember(){
                const member = document.getElementById('member_id_poppay').value;
                
                if(member){
                    const clearMmber = member.replace ( /[^0-9.]/g, '' );
                    if(clearMmber.split('.').length == 1){
                        if(clearMmber.length == 5){
                            const userId = parseInt(clearMmber)
                            if(userId > 0){
                                axios.get('/users/'+userId)
                                .then(resp => {
                                    console.log(resp)
                                    if (resp.status == 200) {
                                        this.memberName = resp.data.data.name
                                    }else{
                                        this.memberName = 'ID Tidak Valid'
                                    }
                                })
                                .catch(err => console.log(err))
                            }else{
                                this.memberName = 'ID Tidak Valid'
                            }
                        }else{
                            this.memberName = 'ID Tidak Valid'
                        }
                    }
                }
            },
            gohome(){
                if(this.splitPay == 'no'){
                    this.$router.push({ name: 'dashboardPage' })
                }else{
                    this.$router.go(0)
                }
            },
            showWarning(msg){
                this.$swal.fire({
                    title:"Oops!", 
                    text:msg, 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            },
            pembulatan(){
                const round = this.currentRound.toLowerCase();
                if(round == "ya") {
                    if(this.currencyPrefix == 'IDR'){
                        this.roundedTotalBayar = roundToDown(parseFloat(this.total), -2);
                        this.exchangeRounded = this.total - this.roundedTotalBayar;
                    }else{
                        const round1 = roundToDown(parseFloat(this.total), 1);
                        const valRound = this.total - round1

                        let finalRound = round1
                        if(valRound >= 0.05){
                            finalRound = round1 + 0.05
                        }

                        this.roundedTotalBayar = finalRound
                        this.exchangeRounded = valRound
                    }
                    
                }else{
                    this.roundedTotalBayar = this.total;
                }
            },
            setupRole(){
                // const callAkses = JSON.parse(localStorage.role_akses)
                // this.cashierRole = callAkses
                axios.get('/akses-role')
                .then(resp => {
                    if (resp.status == 200) {
                    this.cashierRole = resp.data
                    }
                })
                .catch(err => console.log(err))
            },
            currency(price,prefix){
                const nominal = parseFloat(price);
                const format = this.currencyFormat
                const pref = this.currencyPrefix
                const prefView = (prefix != '') ? this.waroengInfo.currency : '';

                const rupiah = new Intl.NumberFormat(format, {
                    style: 'currency', //decimal,currency,percent
                    currency: pref,
                }).format(nominal);
                
                return prefView+' '+rupiah;
            },
            loadPaymentMethod() {
                axios.get('/m-payment-method').then(res => {
                    console.log(res)
                    this.paymentMethod = res.data
                }).catch ((err) => {
                    console.log(err)
                })
            },
            capitalWord(text) {
                return text.toUpperCase()
            },
            setChooseNominal(){
                const round = this.currentRound.toLowerCase();
                if(this.currencyPrefix == 'IDR'){
                    if(round == "ya") {
                        this.roundedTotalBayar = roundToDown(parseFloat(this.total), -2);
                        this.exchangeRounded = this.total - this.roundedTotalBayar;
                    }else{
                        this.roundedTotalBayar = this.total;
                    }

                    this.chooseNominal = [];
                    this.chooseNominal.push(this.roundedTotalBayar)

                    const thousands = Math.floor((this.roundedTotalBayar/100000)+1)
                    const thousandNominal = 100000*thousands
                    this.chooseNominal.push(thousandNominal)

                    if (thousandNominal-(thousandNominal-this.roundedTotalBayar)<50000) {
                        this.chooseNominal.push(thousandNominal-50000)                    
                    }

                    this.estimatedNominal = []
                    const roundUp = Math.ceil(this.roundedTotalBayar/1000)
                    const roundUp2 = Math.ceil(this.roundedTotalBayar/10000)
                    this.estimatedNominal.push(roundUp*1000)
                    this.estimatedNominal.push(roundUp2*10000)
                }else{
                    if(round == "ya") {
                        const round1 = roundToDown(parseFloat(this.total), 1);
                        const valRound = this.total - round1

                        let finalRound = round1
                        if(valRound >= 0.05){
                            finalRound = round1 + 0.05
                        }

                        this.roundedTotalBayar = finalRound
                        this.exchangeRounded = valRound
                    }else{
                        this.roundedTotalBayar = this.total;
                    }

                    this.chooseNominal = [];
                    this.chooseNominal.push(this.roundedTotalBayar)
                    
                    const thousands = Math.floor((this.roundedTotalBayar/100)+1)
                    const thousandNominal = 100*thousands
                    this.chooseNominal.push(thousandNominal)

                    if (thousandNominal-(thousandNominal-this.roundedTotalBayar)<50) {
                        this.chooseNominal.push(thousandNominal-50)                    
                    }

                    this.estimatedNominal = []
                    const roundUp = Math.ceil(this.roundedTotalBayar/1)
                    const roundUp2 = Math.ceil(this.roundedTotalBayar/10)
                    this.estimatedNominal.push(roundUp*1)
                    this.estimatedNominal.push(roundUp2*10)
                }
            },
            confirmCash(){
                const newIn = this.inputNominal;
                this.inputNominal = parseFloat(newIn);
                this.inputNominalValid = this.inputNominal>this.total;
                const cash = parseFloat(document.getElementById("uang_fisik").value);
                console.log('Cash: '+cash)
                if(cash >= this.total){
                    document.getElementById("close-modal-bayar").click()
                    this.setPayNominal(cash)
                }else{
                    this.$swal.fire({
                        title: 'Transaksi ditolak!',
                        html: 'Nominal Pembayaran Kurang',
                        icon: 'error'
                    })
                }
            },
            setInputNominal(){
                // const newIn = this.inputNominal;
                // this.inputNominal = parseFloat(newIn);
                // this.inputNominalValid = this.inputNominal>=this.total
                if (this.inputNominal>=this.roundedTotalBayar) {
                    this.inputNominalValid = true;
                }else{
                    this.inputNominalValid = false;

                }
                console.log(this.inputNominal);
            },
            setPayNominal(nominal){
                this.payNominal = nominal;
                this.exchange = this.payNominal - this.roundedTotalBayar;
                document.getElementById("show-modal-kembalian").click()
            },
            confirmNominal(nominal){
                const a = this.setPayNominal
                const warning = this.showWarning
                this.$swal.fire({
                    title: 'Anda Yakin?',
                    html: 'Menginput nominal '+this.currency(nominal,''),
                    icon: 'info',
                    showCancelButton: 'true',
                    cancelButtonText: 'Tidak',
                    reverseButtons: true,
                    confirmButtonText: 'Ya'
                }).then(function (success) {
                    if (success.isConfirmed) {
                        // setTimeout(() => {
                            document.getElementById("close-modal-bayar").click()
                            a(nominal)
                            axios.get('/open-laci/payment')
                            .then(resp => {
                                console.log(resp.data)
                                if(resp.data.struct != ''){
                                    location.href = resp.data.struct
                                }
                                if(resp.data.error == 1){
                                    warning(resp.data.msg)
                                }
                            })
                            .catch(err => console.log(err))
                        // }, 3000);
                    }
                }) 
            },
            strukCash(wa,print){
                const hpbos = document.getElementById('nomor_hp_bos').value.replace ( /[^0-9.]/g, '' );
                const member = document.getElementById('member_id_poppay').value;
                this.memberIdCustomer = member

                if(hpbos && hpbos.split('.').length == 1){
                    if (hpbos.length < 10) {
                        this.$swal.fire({
                            title:"Warning!", 
                            text:"Nomor WA Tidak Sesuai", 
                            icon:"warning",
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        })
                        return;
                    }
                }

                if(member){
                    if(member.split('.').length == 1){
                        let alert = 0
                        if(member.length == 5){
                            alert = 0
                        }else{
                            if (member.length < 10) {
                                alert = 1
                            }
                        }
                        if (alert == 1) {
                            this.$swal.fire({
                                title:"Warning!", 
                                text:"NIK/ID Member Tidak Sesuai", 
                                icon:"warning",
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok'
                            })
                            return;
                        }
                    }
                }

                if (wa == 1) {
                    if (hpbos && hpbos.split('.').length == 1) {
                        this.kirimWa = hpbos
                        if (print == 1) {
                            this.cetakStruk = 1
                        }
                        this.pay(1)
                    }
                }else{
                    this.cetakStruk = 1
                    this.pay(1)
                }
            },
            pay(paymentMethod){
                // Pembulatan pembayaran EDC
                // const free = parseFloat(document.getElementById("free_kembalian").value) || 0;
                const free = this.exchangeFreeNominal;

                const round = this.currentRound.toLowerCase();
                if(round == "ya") {
                    this.roundedTotalBayar = roundToDown(parseFloat(this.total), -2);
                    this.rounded = this.total - this.roundedTotalBayar;
                }else{
                    this.roundedTotalBayar = this.total;
                    this.rounded = 0;
                }

                // let selisih = this.inputNominalSelisih;
                // if (selisih > 0) {
                //     if (this.statusSelisih == 'kurang') {
                //         selisih = -(this.inputNominalSelisih)
                //     }
                // }
                
                const resultEdc = {
                        paymentMethod: paymentMethod,
                        payNominal: this.total,
                        exchange: 0,
                        freeExchange: 0,
                        exchangeRounded: this.rounded,
                        cetakStruk: 0,
                        selisih: this.inputNominalSelisih
                    }

                const resultCash = {
                    paymentMethod: paymentMethod,
                    payNominal: this.payNominal,
                    exchange: this.exchange,
                    freeExchange: free,
                    exchangeRounded: this.exchangeRounded,
                    nomorWa: this.kirimWa,
                    memberId: this.memberIdCustomer,
                    cetakStruk: this.cetakStruk,
                    selisih: this.inputNominalSelisih
                }
                // document.getElementById('close-modal-exchange').click()
                document.getElementById('closeChooseEdc').click()
                
                if (paymentMethod == 1) {
                    this.buttonStruk = true
                    this.buttonCloseStruk = false
                    this.$emit('onPay', resultCash)
                }else{
                    this.$emit('onPay', resultEdc)   
                }
            },
            confirmLostbill(){
                const tmpId = this.transId
                const sub_total = this.sub_total
                const total = this.total
                const global_discount = this.discount
                const nominal_voucher = this.voucher
                const nominal_pajak = this.pajak
                const nominal_sc = this.service
                const nominal_tariktunai = this.tunai
                const warning = this.showWarning

                // this.setupRole()
                // Cek Role before Execute
                axios.get('/akses-role')
                .then(resp => {
                    if (resp.status == 200) {
                        this.cashierRole = resp.data
                        if (this.cashierRole.lossbill_transaksi.defaultRole == 'deny' && this.cashierRole.lossbill_transaksi.temporaryRole == 'deny') {
                            this.$swal.fire({
                                title:"Akses DiTolak!", 
                                text:"Hubungi Kacab/Asisten", 
                                icon:"warning",
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok'
                            })
                        }else{
                            const round = this.currentRound.toLowerCase();
                            if(round == "ya") {
                                this.roundedTotalBayar = roundToDown(parseFloat(this.total), -2);
                                this.exchangeRounded = this.total - this.roundedTotalBayar;
                            }else{
                                this.exchangeRounded = 0;
                                this.roundedTotalBayar = this.total;
                            }
                            const nominal_round = this.exchangeRounded; 
                            const total_round = this.roundedTotalBayar;
                            this.$swal.fire({
                                title: 'Anda Yakin?',
                                html: 'Transaksi ini Lostbill',
                                icon: 'info',
                                showCancelButton: 'true',
                                cancelButtonText: 'Tidak',
                                reverseButtons: true,
                                confirmButtonText: 'Ya'
                            }).then(function (success) {
                                if (success.isConfirmed) {
                                    const formData = new FormData()
                                    formData.append('tmp_transaction_id', tmpId)
                                    formData.append('payment_method', 0)
                                    formData.append('sub_total', sub_total)
                                    formData.append('total', total)
                                    formData.append('nominal_uang', 0)
                                    formData.append('exchange', 0)
                                    formData.append('round', 0)
                                    formData.append('free_exchange', 0)
                                    formData.append('global_discount', global_discount)
                                    formData.append('nominal_voucher', nominal_voucher)
                                    formData.append('nominal_pajak', nominal_pajak)
                                    formData.append('nominal_sc', nominal_sc)
                                    formData.append('nominal_tariktunai', nominal_tariktunai)
                                    formData.append('nomor_wa', '')
                                    formData.append('member_id', '')
                                    formData.append('cetak_struk', 1)
                                    formData.append('selisih', 0)

                                    axios.post('/recap-transactions', formData).then(res => {
                                        console.log(res.data);
                                        if(res.data.meta.error == true){
                                            warning(res.data.meta.message)
                                        }
                                        if (res.status == 201) {
                                            const resp = res.data.data
                                            const rekapTransId = resp.rekap_id
                                            const repeat = 1
                                            console.log(rekapTransId);
                                            axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
                                                if(resp.data.error == 0){
                                                    if(resp.data.struct != ''){
                                                        location.href = resp.data.struct
                                                    }
                                                }
                                                console.log(resp.data)
                                                document.getElementById("close-bayar").click()
                                                window.location.replace("../dashboard");
                                            })
                                            
                                        }

                                    }).catch ((err) => {
                                        if (err.response) {
                                        console.log(err.response.data)
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
                .catch(err => console.log(err))
                
            }
        }
    }
</script>

<style scoped>

</style>