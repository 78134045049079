<template>
  <div>
    <Nav></Nav>

    <section class="py-4 section-app">
      <div class="container-fluid">
        <div class="row mx-n2">
          <div class="col-lg-5 px-2">
            <div class="card bg-light">
              <div class="card-body p-3">
                <div class="row mx-n2">
                  <div class="col-lg-5 px-2">

                    <table width="100%">
                      <tr v-if="notaDetailList.tmp_transaction_table_name_list != ''">
                        <td width="100">Nomor Meja</td>
                        <td width="10">:</td>
                        <td>
                          <strong>{{ notaDetailList.tmp_transaction_table_name_list }}</strong>
                        </td>
                      </tr>
                      <tr v-else>
                        <td width="100">Online Order</td>
                        <td width="10">:</td>
                        <td>
                          <strong>{{ notaDetailList.m_t_t_name }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Nomor Nota</td>
                        <td>:</td>
                        <td>
                          <strong>{{
                            notaDetailList.tmp_transaction_note_number
                          }}</strong>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-5 px-2">
                    <table width="100%">
                      <tr>
                        <td width="100">Bigboss</td>
                        <td width="10">:</td>
                        <td>
                          <strong>{{
                            notaDetailList.tmp_transaction_customer_name
                          }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Kasir</td>
                        <td>:</td>
                        <td>
                          <strong>{{
                            notaDetailList.tmp_transaction_by
                          }}</strong>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-2 px-2">
                    <button type="button" class="btn btn-accent"
                    data-dismiss="modal" aria-label="Close" 
                    data-toggle="modal" data-target="#modal-update-transaction-nota" data-backdrop="static"
                    v-on:click="updateNota(tmpId)">Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-2">
              <div
                class="table-responsive"
                style="max-height: calc(100vh - 350px); height: 100vh"
              >
                <table class="table table-menu m-0 font-summary">
                  <thead class="bg-dark text-white">
                    <tr>
                      <th>#</th>
                      <th>Nama Menu</th>
                      <!-- <th class="text-center">Produksi</th> -->
                      <th class="text-left">Qty</th>
                      <th class="text-right">Harga</th>
                      <th class="text-right">Total</th>
                      <th width="120" class="text-center">Opsi</th>
                    </tr>
                  </thead>
                  <tbody v-for="menuJenis in cart" :key="menuJenis.id">
                    <tr>
                      <td colspan="7" class="bg-accent text-white">
                        {{ menuJenis.name }}
                      </td>
                    </tr>
                    <template v-for="(listMenus, index) in menuJenis.items"
                      :key="listMenus.tmp_transaction_detail_id">
                      <tr :style="[(menuOnKitchen == 1 && listMenus.tmp_transaction_detail_production_status == 0) ?{'background': '#d7ffd5'} : {'background': '#FFF'}]">
                        <td>{{ index + 1 }}</td>
                        <td @click="addMultiQty(listMenus.tmp_transaction_detail_id,capitalFirst(listMenus.m_produk_nama),listMenus.tmp_transaction_detail_qty,listMenus.tmp_transaction_detail_status)">
                          <sup v-if="listMenus.tmp_transaction_detail_production_status == 1" class="text-success">K</sup>
                          <sup v-if="listMenus.tmp_transaction_detail_tax_status" class="text-danger">P</sup>
                          <sup v-if="listMenus.tmp_transaction_detail_service_charge_status" class="text-primary">S</sup>{{ capitalFirst(listMenus.m_produk_nama) }} 
                          <span v-if="listMenus.m_menu_harga_qr_status == 'tidak'" class="text-right text-nowrap text-danger">
                            Sold Out!
                          </span>
                          <span v-if="listMenus.tmp_transaction_detail_status != 0 && listMenus.tmp_transaction_detail_status != 2" class="text-right text-nowrap text-danger">
                          (Garansi)
                          </span>
                          <span v-if="listMenus.tmp_transaction_detail_status == 2" class="text-right text-nowrap text-danger">
                          (Gratis)
                          </span>
                        </td>
                        <!-- <td class="text-center">
                          <span
                            v-if="
                              listMenus.tmp_transaction_detail_production_status == 0
                            "
                            >Belum</span
                          >
                          <span v-else class="text-danger">Sudah</span>
                        </td> -->
                        <td class="text-center">
                          <div
                            style="display: flex; align-items: center"
                            class="input-qty"
                          >
                            <template v-if="listMenus.tmp_transaction_detail_qty_qr > 0">
                              <sup v-if="listMenus.tmp_transaction_detail_qty_qr > listMenus.tmp_transaction_detail_qty" class="text-danger">-{{ listMenus.tmp_transaction_detail_qty_qr-listMenus.tmp_transaction_detail_qty }}</sup>
                            </template>
                            <button v-if="listMenus.tmp_transaction_detail_status==0" style="width: 20px; height: 20px; border:1px solid #ddd; font-size:10px;" v-on:click="reduceMenutoNota(listMenus.tmp_transaction_detail_id, listMenus.tmp_transaction_detail_tmp_transaction_id)" 
                            class="btn-minus" 
                            :class="{ isDisabled: !addProduk }"
                            type="button"><i class="fa fa-minus"></i></button>
                            <!-- <button
                              v-if="listMenus.tmp_transaction_detail_status == 0"
                              style="
                                width: 20px;
                                height: 20px;
                                border: 1px solid #ddd;
                                font-size: 10px;
                              "
                              v-on:click="selectedReduceMenu = listMenus"
                              data-toggle="modal"
                              data-target="#modal-reduce-menu"
                              class="btn-minus"
                              type="button"
                            >
                              <i class="fa fa-minus"></i>
                            </button> -->
                            <div
                              style="
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                              "
                              class="value"
                            >
                              {{ listMenus.tmp_transaction_detail_qty }}
                            </div>
                            <button
                              v-if="listMenus.tmp_transaction_detail_status == 0"
                              style="
                                width: 20px;
                                height: 20px;
                                border: 1px solid #ddd;
                                font-size: 10px;
                              "
                              v-on:click="
                                addMenutoNota(
                                  listMenus.tmp_transaction_detail_id,
                                  null,
                                  listMenus.tmp_transaction_detail_tmp_transaction_id
                                )
                              "
                              class="btn-minus"
                              :class="{ isDisabled: !addProduk }"
                              type="button"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                            <template v-if="listMenus.tmp_transaction_detail_qty_qr > 0">
                              <sup v-if="listMenus.tmp_transaction_detail_qty_qr < listMenus.tmp_transaction_detail_qty" class="text-danger">+{{ listMenus.tmp_transaction_detail_qty-listMenus.tmp_transaction_detail_qty_qr }}</sup>
                            </template>
                          </div>
                        </td>
                        <td class="text-right text-nowrap">
                          <!-- <span
                            v-if="
                              listMenus.tmp_transaction_detail_discount_type == 'Qty'
                            "
                          >
                            {{ parseFloat(listMenus.m_menu_harga_nominal) }}
                          </span>
                          <span v-else> -->
                            {{
                            currency(parseFloat(
                                listMenus.tmp_transaction_detail_price
                              ),'')
                            }}
                          <!-- </span> -->
                        </td>
                        <td
                          v-if="listMenus.tmp_transaction_detail_status == 0"
                          class="text-right text-nowrap"
                        >
                          {{ currency(listMenus.tmp_transaction_detail_nominal,'') }}
                        </td>
                        <td v-else class="text-right text-nowrap text-danger">
                          {{ currency(listMenus.tmp_transaction_detail_nominal,'') }}
                        </td>
                        <td class="text-center">
                          <template
                            v-if="listMenus.tmp_transaction_detail_status == 0"
                          >
                          <a
                              v-if="!listMenus.tmp_transaction_detail_reasone_approve && listMenus.tmp_transaction_detail_production_status == 0"
                              v-on:click="customMenu(listMenus)"
                              href="javascript:void(0)"
                              class="text-accent text-decoration-none h6"
                              ><i class="fa fa-user"></i></a>
                            <span
                            v-if="!listMenus.tmp_transaction_detail_reasone_approve && listMenus.tmp_transaction_detail_production_status == 0 && userRole != 'opp'"
                            class="mx-2"
                            >|</span>
                            <a
                              v-if="!listMenus.tmp_transaction_detail_reasone_approve && userRole != 'opp'"
                              v-on:click="editMenu(listMenus)"
                              href="javascript:void(0)"
                              class="text-accent text-decoration-none h6"
                              ><i class="fa fa-edit"></i
                            ></a>
                            <span
                              v-if="!listMenus.tmp_transaction_detail_reasone_approve && userRole != 'opp'"
                              class="mx-2"
                              >|</span>
                            <a v-if="userRole != 'opp'"
                              v-on:click="
                                garansiMenu(
                                  listMenus.tmp_transaction_detail_m_produk_id,
                                  listMenus.tmp_transaction_detail_tmp_transaction_id,
                                  listMenus.tmp_transaction_detail_id,
                                  listMenus.m_produk_nama
                                ),
                                  setPembatalanMenu()
                              "
                              href="javascript:void(0)"
                              class="text-accent text-decoration-none h6"
                              data-toggle="modal"
                              data-target="#modal-cancel-menu"
                              ><i class="fa fa-undo"></i
                            ></a>
                          </template>
                          <template v-else>
                            <span class="mx-2">
                              {{ listMenus.tmp_transaction_detail_reasone_approve }}
                            </span>
                            <!-- <span
                              v-if="listMenus.tmp_transaction_detail_status == 2"
                              class="mx-2"
                              >{{
                                listMenus.tmp_transaction_detail_reasone_approve
                              }}</span
                            >
                            <span
                              v-if="listMenus.tmp_transaction_detail_status == 3"
                              class="mx-2"
                              > Produk Gratis
                              {{
                                listMenus.tmp_transaction_detail_reasone_approve
                              }}</span
                            >
                            <span
                              v-if="listMenus.tmp_transaction_detail_status == 4"
                              class="mx-2"
                              >Gratis -
                              {{
                                listMenus.tmp_transaction_detail_reasone_approve
                              }}</span
                            >
                            <span
                              v-if="listMenus.tmp_transaction_detail_status == 5"
                              class="mx-2"
                              >Garan -
                              {{
                                listMenus.tmp_transaction_detail_reasone_approve
                              }}</span
                            > -->
                          </template>
                        </td>
                      </tr>
                      <!-- <template v-if="listMenus.tmp_transaction_detail_custom_menu != ''"> -->
                        <tr v-if="listMenus.tmp_transaction_detail_custom_menu || listMenus.tmp_transaction_detail_takeaway_status == 1">
                          <td></td>
                          <!-- <td colspan="6" class="text-dark" style="background:#FFF8DC;"> -->
                          <td colspan="6" class="bg-secondary text-white">
                            <template v-if="listMenus.tmp_transaction_detail_takeaway_status == 1">(Take Away)</template>
                            {{listMenus.tmp_transaction_detail_custom_menu}}
                          </td>
                        </tr>
                      <!-- </template> -->
                    </template>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card mt-2">
              <table class="table table-menu table-condensed m-0 font-summary" >
                <tr>
                  <td class="text-right"><strong>Sub Total</strong></td>
                  <td width="5" class="px-0"><strong>:</strong></td>
                  <!-- <td width="10"><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger" width="150">
                    {{ currency(subTotalNotaListMenu,'') }}
                  </td>
                  <td width="10"></td>
                </tr>
                <tr>
                  <td class="text-right">
                    <strong
                      >Pajak Resto ({{ this.currentPajak * 100 }}%)</strong
                    >
                  </td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger">
                    {{ currency(pajakNotaListMenu,'') }}
                  </td>
                  <template v-if="notaDetailList.pajak > 0 && this.userRole == 'kasir'">
                    <template v-if="currentPajak > 0">
                      <td class="p-0 px-2">
                        <a
                          href="javascript:void(0)"
                          v-on:click="setNullPajak()"
                          class="text-accent text-decoration-none h5"
                          ><i class="fa fa-trash-o"></i
                        ></a>
                      </td>
                    </template>
                    <template v-else>
                      <td class="p-0 px-2">
                        <a
                          href="javascript:void(0)"
                          v-on:click="setPajak()"
                          class="text-primary text-decoration-none h5"
                          ><i class="fa fa-plus-circle"></i
                        ></a>
                      </td>
                    </template>
                  </template>
                </tr>
                <tr>
                  <td class="text-right">
                    <strong>Service Charge ({{ this.currentService * 100 }}%)</strong>
                  </td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger">
                    {{ currency(ServiceNotaListMenuPrice,'') }}
                  </td>
                  <template v-if="notaDetailList.service_charge > 0">
                    <template v-if="currentService > 0">
                      <td class="p-0 px-2">
                        <a
                          href="javascript:void(0)"
                          v-on:click="setNullService()"
                          class="text-accent text-decoration-none h5"
                          ><i class="fa fa-trash-o"></i
                        ></a>
                      </td>
                    </template>
                    <template v-else>
                      <td class="p-0 px-2">
                        <a
                          href="javascript:void(0)"
                          v-on:click="setService()"
                          class="text-primary text-decoration-none h5"
                          ><i class="fa fa-plus-circle"></i
                        ></a>
                      </td>
                    </template>
                  </template>
                </tr>
                <tr v-if="this.userRole == 'kasir'">
                  <td class="text-right"><strong>Tarik Tunai</strong></td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger">
                    {{ currency(TarikTunaiNotaListMenu,'') }}
                  </td>
                  <td class="p-0 px-2">
                    <!-- <a
                      href="javascript:void(0)"
                      v-on:click="setTarikTunai()"
                      class="text-primary text-decoration-none h5"
                      ><i class="fa fa-plus-circle"></i
                    ></a> -->
                    <a v-if="TarikTunaiNotaListMenu == 0" href="#modal-input-tunai"
                        data-toggle="modal"
                        data-target="#modal-input-tunai"
                        data-dismiss="modal"
                        class="text-primary text-decoration-none h5"
                      ><i class="fa fa-plus-circle"></i
                    ></a>
                    <a v-if="TarikTunaiNotaListMenu > 0" href="" @click.prevent="removeTarikTunai" title="Hapus Diskon" 
                      class="text-accent text-decoration-none h5" alt="Hapus Tarik">
                      <i class="fa fa-trash-o"></i></a>
                  </td>
                </tr>
                
                <tr v-if="DiskonKhusus > 0">
                  <td class="text-right"><strong>Diskon</strong></td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger">
                    {{ currency(DiskonKhusus,'') }}
                  </td>
                  <td v-if="this.userRole == 'kasir'" class="p-0 px-2">
                    <a href="" @click.prevent="removeDiskonKhusus" title="Hapus Diskon" 
                      class="text-accent text-decoration-none h5" alt="Hapus Diskon">
                      <i class="fa fa-trash-o"></i></a>
                  </td>
                </tr>
                <tr v-if="DiskonVoucher > 0">
                  <td class="text-right"><strong>Voucher</strong></td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-danger">
                    {{ currency(DiskonVoucher,'') }}
                  </td>
                  <td v-if="this.userRole == 'kasir'" class="p-0 px-2">
                    <a href="" @click.prevent="removeDiskonVoucher" title="Hapus Diskon" 
                      class="text-accent text-decoration-none h5" alt="Hapus Diskon">
                      <i class="fa fa-trash-o"></i></a>
                  </td>
                </tr>
                <tr>
                  <td class="text-right"><strong>Total Bayar</strong></td>
                  <td class="px-0"><strong>:</strong></td>
                  <!-- <td><strong>{{this.waroengInfo.currency}}</strong></td> -->
                  <td class="text-right text-bold text-danger" style="color:#666;border:1px solid;">
                    <h5>{{ currency(TotalNotaListMenu,'') }}</h5>
                    
                  </td>
                  <td class="p-0 px-2"></td>
                </tr>

              </table>
            </div>

            <div class="card mt-2">
              <div class="card-body p-2">
                <div class="row mx-n1 justify-content-center">
                  <div v-if="userRole != 'opp'" class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-danger font-weight-bold btn-sm"
                      type="button"
                      v-on:click="deleteTransaction()"
                    >
                      <i class="fa fa-trash-o"></i>
                      HAPUS
                    </button>
                  </div>
                  <!-- <div class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-warning font-weight-bold btn-sm"
                      type="button"
                      v-on:click="getPullMenu()"
                      data-toggle="modal"
                      data-target="#modal-keluarkan-menu"
                      data-dismiss="modal"
                    >
                      KELUARKAN MENU
                    </button>
                  </div> -->
                  <template v-if="subTotalNotaListMenu > 0">
                  <div class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-primary font-weight-bold btn-sm"
                      type="button"
                      v-on:click="saveTransaction()"
                    >
                      SIMPAN
                    </button>
                  </div>
                  <div v-if="this.userRole != 'opp'" class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-success font-weight-bold btn-sm"
                      data-toggle="modal"
                      data-target="#modal-bayar"
                      data-dismiss="modal"
                      type="button"
                      :disabled='!addProduk'
                      v-on:click="kroscekBayar()"
                    >
                      BAYAR
                    </button>
                  </div>
                  <div v-if="this.userRole != 'opp'" class="col-auto px-1 mb-1">
                    <a
                      v-on:click="splitMenuOrder()"
                      href="javascript:void(0)"
                      class="btn btn-warning font-weight-bold btn-sm"
                      >SPLIT</a
                    >
                  </div>
                  <div v-if="this.userRole != 'opp'" class="col-auto px-1 mb-1">
                    <button
                      v-on:click="printBill()"
                      class="btn btn-info font-weight-bold btn-sm"
                      type="button"
                    >
                      PRINTBILL
                    </button>
                  </div>
                  <div v-if="this.userRole == 'opp'" class="col-auto px-1 mb-1">
                    <button
                      v-on:click="cetakRepeatPesanan()"
                      class="btn btn-secondary font-weight-bold btn-sm"
                      type="button"
                    >
                      REPEAT
                    </button>
                  </div>
                  <div v-if="this.userRole != 'wbd'" class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-accent font-weight-bold btn-sm text-light"
                      type="button"
                      v-on:click="setWarningMenu()"
                      data-toggle="modal"
                      data-target="#modal-order-produksi"
                    >
                      ORDER KE PRODUKSI
                    </button>
                  </div>
                  <!-- <div class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-warning font-weight-bold btn-sm text-light"
                      type="button"
                      v-on:click="setWarningMenu()"
                      data-toggle="modal"
                      data-target="#modal-order-produksi"
                    >
                      ORDER KE PRODUKSI
                    </button>
                  </div> -->
                  <!-- <div
                    v-if="
                      notaDetailList.m_t_t_name != 'dine in' &&
                      notaDetailList.m_t_t_name != 'take away'
                    "
                    class="col-auto px-1 mb-1"
                  >
                    <a
                      v-on:click="completeTransaction()"
                      href="javascript:void(0)"
                      class="btn btn-success font-weight-bold btn-sm"
                      >SELESAIKAN TRANSAKSI</a
                    >
                  </div> -->
                  <div v-if="this.userRole != 'opp'" class="col-auto px-1 mb-1">
                    <button
                      class="btn btn-secondary font-weight-bold btn-sm"
                      data-toggle="modal"
                      data-target="#modal-diskon"
                      data-dismiss="modal"
                      type="button"
                    >
                      DISKON
                    </button>
                  </div>
                  </template>
                  <!-- <button id="show-modal-struk" data-toggle="modal" data-target="#modal-struk" data-backdrop="static">coba</button> -->
                  <!-- <div class="col-auto px-1 mb-1">
                    <a href="" class="btn btn-secondary btn-sm font-weight-bold" 
                      @click.prevent="othersHandle">LAINNYA</a>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 mt-4 mt-lg-0 px-2">
            <div class="card">
              <div class="table-responsive bg-accent">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                      <span class="input-group-text bg-accent" 
                      style="border: 1px solid #653332;" id="basic-addon1">
                        <i class="fa fa-search text-white"></i>
                      </span>
                  </div>
                  <input type="text" 
                  v-model="searchMenu"
                  v-on:keyup="searhMenuBy()"
                  placeholder="Search Menu"
                  class="form-control" 
                  style="border:1px solid #653332;border-radius: 0rem;"
                  aria-label="nota" aria-describedby="basic-addon1" minlength="3">
                </div>
                <ul class="nav nav-menu flex-nowrap text-nowrap" ref="menuList">
                  <li v-on:click="loadAllMenu()" class="nav-item">
                    <a href="#" class="nav-link active" data-toggle="tab"
                      >Semua</a
                    >
                  </li>
                  <li
                    :id="stockMeja.m_jenis_produk_id"
                    v-on:click="loadKategoriList(stockMeja)"
                    v-for="stockMeja in stockMenuList"
                    :key="stockMeja.m_jenis_produk_id"
                    class="nav-item"
                  >
                    <a
                      href="#"
                      class="nav-link"
                      data-toggle="tab"
                      :data-target="stockMeja.m_jenis_produk_id"
                      >{{ capitalFirst(stockMeja.m_jenis_produk_nama) }}</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="tab-content"
                style="max-height: calc(100vh - 136.5px); height: 100vh"
              >
                <div class="tab-pane active" id="minuman">
                  <template v-if="stockMenuKategoriList.length > 0">
                    <div class="table-responsive border-bottom">
                      <ul class="nav nav-submenu flex-nowrap text-nowrap">
                        <li
                          v-on:click="loadMenuList(selectedMenuJenisId)"
                          class="nav-item"
                        >
                          <a
                            href="#"
                            class="nav-link active"
                            data-toggle="tab"
                            data-target="#minuman-semua"
                            >Semua</a
                          >
                        </li>
                        <li
                          v-on:click="
                            loadMenuByKategori(kategori.m_sub_jenis_produk_id)
                          "
                          :id="kategori.m_sub_jenis_produk_id"
                          v-for="kategori in stockMenuKategoriList"
                          :key="kategori.m_sub_jenis_produk_id"
                          class="nav-item"
                        >
                          <a
                            href="#"
                            class="nav-link"
                            data-toggle="tab"
                            :data-target="kategori.m_sub_jenis_produk_id"
                            >{{ capitalFirst(kategori.m_sub_jenis_produk_nama) }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </template>
                  <div class="tab-content">
                    <div
                      class="tab-pane active p-3"
                      id="minuman-semua"
                      style="max-height: calc(100vh - 175px); overflow: auto"
                    >
                      <div class="row row-menu-list">
                        <div
                          v-for="rowStock in stockList"
                          :key="rowStock.m_produk_id"
                          class="col-sm-3 col-md-2 col-lg-2 col-xl-2 mb-2"
                        >
                          <a
                            v-if="rowStock.type == 'hot'"
                            href="javascript:void(0)"
                            v-on:click="
                              addMenutoNota(
                                null,
                                rowStock.m_produk_id,
                                $route.params.transactionId
                              )
                            "
                            class="card card-menu hot"
                            :class="{ isDisabled: !addProduk }"
                            data-toggle="modal"
                          >
                            <div class="card-body font-weight-bold font-order">
                              {{ capitalFirst(rowStock.m_produk_nama) }}
                            </div>
                            <div class="card-footer font-order">
                              {{ currency(rowStock.m_menu_harga_nominal,'Rp') }}
                            </div>
                          </a>

                          <a
                            v-else-if="rowStock.type == 'Ice'"
                            href="javascript:void(0)"
                            v-on:click="
                              addMenutoNota(
                                null,
                                rowStock.m_produk_id,
                                $route.params.transactionId
                              )
                            "
                            class="card card-menu ice"
                            :class="{ isDisabled: !addProduk }"
                            data-toggle="modal"
                          >
                            <div class="card-body font-weight-bold font-order">
                              {{ capitalFirst(rowStock.m_produk_nama) }}
                            </div>
                            <div class="card-footer font-order">
                              {{ currency(rowStock.m_menu_harga_nominal,'Rp') }}
                            </div>
                          </a>
                          <a
                            v-else-if="rowStock.type == 'juice'"
                            href="javascript:void(0)"
                            v-on:click="
                              addMenutoNota(
                                null,
                                rowStock.m_produk_id,
                                $route.params.transactionId
                              )
                            "
                            class="card card-menu juice"
                            :class="{ isDisabled: !addProduk }"
                            data-toggle="modal"
                          >
                            <div class="card-body font-weight-bold font-order">
                              {{ capitalFirst(rowStock.m_produk_nama) }}
                            </div>
                            <div class="card-footer font-order">
                              {{ currency(rowStock.m_menu_harga_nominal,'Rp') }}
                            </div>
                          </a>
                          <a
                            v-else-if="rowStock.type == 'bakar'"
                            href="javascript:void(0)"
                            v-on:click="
                              addMenutoNota(
                                null,
                                rowStock.m_produk_id,
                                $route.params.transactionId
                              )
                            "
                            class="card card-menu bakar"
                            :class="{ isDisabled: !addProduk }"
                            data-toggle="modal"
                          >
                            <div class="card-body font-weight-bold font-order">
                              {{ capitalFirst(rowStock.m_produk_nama) }}
                            </div>
                            <div class="card-footer font-order">
                              {{ currency(rowStock.m_menu_harga_nominal,'Rp') }}
                            </div>
                          </a>
                          <a
                            v-else
                            href="javascript:void(0)"
                            v-on:click="
                              addMenutoNota(
                                null,
                                rowStock.m_produk_id,
                                $route.params.transactionId
                              )
                            "
                            class="card card-menu"
                            :class="{ isDisabled: !addProduk }"
                            data-toggle="modal"
                          >
                            <div class="card-body font-weight-bold font-order">
                              {{ capitalFirst(rowStock.m_produk_nama) }}
                            </div>
                            <div class="card-footer font-order">
                              {{ currency(rowStock.m_menu_harga_nominal,'Rp') }}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <button id="show-modal-custom-menu" data-toggle="modal" data-target="#modal-custom-menu"/>
    <div class="modal fade" id="modal-custom-menu">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="titleCustomMenu">Custom Menu</h6>
            <button
              id="close-custom-modal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
          </div>
          <form
            class="form"
            id="transactionCustomMenu"
            @submit.prevent="transactionCustomMenu('transactionCustomMenu')"
            method="post"
          >
            <input
              type="hidden"
              class="transactionMenuId"
              id="tmp_transaction_detail_id_custom"
              name="tmp_transaction_detail_id"
              required
            />
            <div class="modal-body">
              <div class="form-group">
                <label for="">Quantity
                  <span class="text-danger">(only for customize)</span>
                </label>
                <input
                  id="tmp_transaction_detail_qty_custom"
                  name="tmp_transaction_detail_qty"
                  type="number"
                  min="1"
                  class="form-control numeric"
                  required
                />
              </div>
              <div class="form-group row align-items-center">
                <label class="col-form-label col">Takeaway</label>
                <div class="col-md-3">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="tmp_transaction_detail_takeaway_status"
                      value="1"
                      id="tmp_transaction_detail_takeaway_status_custom"
                    />
                    <label
                      class="custom-control-label"
                      for="tmp_transaction_detail_takeaway_status_custom"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">Catatan</label>
                <textarea
                  id="tmp_transaction_detail_custom_menu_custom"
                  name="tmp_transaction_detail_custom_menu"
                  cols="30"
                  rows="5"
                  class="form-control"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <button id="show-modal-edit-menu" data-toggle="modal" data-target="#modal-edit-menu"/>
    <div class="modal fade" id="modal-edit-menu">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="titleEditMenu">Edit Menu</h6>
            <button
              id="close-edit-modal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
          </div>
          <form
            class="form"
            id="transactionEditMenu"
            @submit.prevent="transactionEditMenu('transactionEditMenu')"
            method="post"
          >
            <input
              type="hidden"
              class=""
              value="editMenu"
              name="tmp_transaction_detail_type"
              required
            />
            <input
              type="hidden"
              class="inputMenuId"
              name="tmp_transaction_detail_m_produk_id"
              required
            />
            <input
              type="hidden"
              class="inputNotaId"
              name="tmp_transaction_detail_tmp_transaction_id"
              required
            />
            <input
              type="hidden"
              class="transactionMenuId"
              name="tmp_transaction_detail_id"
              required
            />
            <div class="modal-body">
              <div class="form-group row align-items-center">
                <label class="col-form-label col">Pajak</label>
                <div class="col-auto">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="tmp_transaction_detail_tax"
                      value="1"
                      id="pajak_edit_menu_modal"
                    />
                    <label
                      class="custom-control-label"
                      for="pajak_edit_menu_modal"
                    ></label>
                  </div>
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label class="col-form-label col">Service Charge</label>
                <div class="col-auto">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="tmp_transaction_detail_service_charge"
                      value="1"
                      id="service_charge_edit_menu_modal"
                    />
                    <label
                      class="custom-control-label"
                      for="service_charge_edit_menu_modal"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="py-2 font-weight-bold">Potongan / Harga</label
                ><br />
              </div>
              <div class="form-group row align-items-center mx-n1">
                <div class="col-lg-6 col-md-6 col-sm-6 px-1">
                  <select
                    name="tmp_transaction_detail_discount_type"
                    id="tmp_transaction_detail_discount_type_edit_menu_modal"
                    class="form-control option"
                  >
                    <option value="Persen">Persen</option>
                    <option value="Nominal">Potongan Per Item</option>
                    <option value="Qty">Qty</option>
                    <option value="CustomPrice">Harga Per Item</option>
                  </select>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-1">
                  <!-- v-model.lazy="editMenuPrice" v-money="config" -->
                  <input
                    name="tmp_transaction_detail_discount"
                    id="tmp_transaction_detail_discount_edit_menu_modal"
                    type="text"
                    class="form-control numeric ui-keyboard-input ui-widget-content ui-corner-all ui-keyboard-input-current"
                  />
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="py-4 font-weight-bold">Custom Menu</label><br />
                <div
                  v-for="(menu, index) in customListMenu"
                  :key="index"
                  class="form-group row align-items-center"
                >
                  <label for="" class="col-4">{{ capitalFirst(menu.m_produk_nama) }}</label>
                  <div class="col-8">
                    <input
                      type="hidden"
                      name="tmp_transaction_detail_id_custom_menu[]"
                      :value="menu.tmp_transaction_detail_id"
                    />
                    <input
                      type="text"
                      name="tmp_transaction_detail_custom_menu[]"
                      class="form-control"
                      placeholder="Custom"
                      :value="menu.tmp_transaction_detail_custom_menu"
                    />
                  </div>
                </div>
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-cancel-menu">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="titleCancelMenu">Cancel Menu</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs">
              <!-- <li class="nav-item"><a href="javascript:void(0)" id="menu-tab-pembatalan" v-on:click="setPembatalanMenu()" class="nav-link active" data-toggle="tab" data-target="#tab-pembatalan">Pembatalan</a></li> -->
              <li class="nav-item">
                <a
                  href="javascript:void(0)"
                  id="menu-tab-garansi"
                  v-on:click="setGaransiMenu()"
                  class="nav-link active"
                  data-toggle="tab"
                  data-target="#tab-garansi"
                  >Garansi</a
                >
              </li>
            </ul>
            <div class="tab-content p-3">
              <div class="tab-pane" id="tab-pembatalan">
                <form
                  class="form"
                  id="transactionPembatalanMenu"
                  @submit.prevent="
                    transactionGaransiMenu('transactionPembatalanMenu')
                  "
                  method="post"
                >
                  <div class="form-group">
                    <label for="">Quantity</label>
                    <input
                      type="hidden"
                      class="inputMenuId"
                      name="tmp_transaction_detail_m_produk_id"
                      required
                    />
                    <input
                      type="hidden"
                      class="inputNotaId"
                      name="tmp_transaction_detail_tmp_transaction_id"
                      required
                    />
                    <input
                      type="hidden"
                      class="transactionMenuId"
                      name="tmp_transaction_detail_id"
                      required
                    />
                    <!-- <input
                      type="hidden"
                      value="2"
                      name="status-menu"
                      required
                    /> -->
                    <input
                      type="number"
                      name="qty-approve"
                      class="form-control numeric pembatalan-menu-tab"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Alasan Pembatalan</label>
                    <textarea
                      name="reason-approve"
                      cols="30"
                      rows="5"
                      class="form-control pembatalan-menu-tab"
                      required
                    ></textarea>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
              <div class="tab-pane active" id="tab-garansi">
                <form
                  class="form"
                  id="transactionGaransiMenu"
                  @submit.prevent="
                    transactionGaransiMenu('transactionGaransiMenu')
                  "
                  method="post"
                >
                  <div class="form-group">
                    <input
                      type="hidden"
                      class="inputMenuId"
                      name="tmp_transaction_detail_m_produk_id"
                    />
                    <input
                      type="hidden"
                      class="inputNotaId"
                      name="tmp_transaction_detail_tmp_transaction_id"
                    />
                    <input
                      type="hidden"
                      class="transactionMenuId"
                      name="tmp_transaction_detail_id"
                      required
                    />
                    <label for="">Aksi</label>
                    <select
                      name="status-menu"
                      class="form-control option garansi-menu-tab"
                      required
                    >
                      <option value="3">Ganti-Produk Sama </option>
                      <option value="4">Ganti-Produk Lain</option>
                      <option value="5">Gratis</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="">Quantity</label>
                    <input
                      name="qty-approve"
                      type="text"
                      class="form-control numeric garansi-menu-tab"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Alasan Garansi</label>
                    <textarea
                      name="reason-approve"
                      cols="30"
                      rows="5"
                      class="form-control garansi-menu-tab keyboard"
                      required
                    ></textarea>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      id="closeModalGaransi"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Order Produksi -->
    <div class="modal fade" id="modal-order-produksi">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-accent text-white border-0">
            <h5 class="modal-title">
              Pastikan lagi pesanan
              <strong class="text-danger">Sudah Benar!</strong>
            </h5>
            <button
              type="button"
              class="close text-white"
              id="close-warning"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              v-for="(menuJenis, index) in warningMenu"
              class="card mb-2"
              :key="index"
            >
              <div class="card-header bg-accent text-white font-weight-bold">
                {{ menuJenis.name }}
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr class="border-bottom">
                      <th>No</th>
                      <th>Nama Menu</th>
                      <th class="text-right">Qty</th>
                      <th>Custom</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(menu, index) in menuJenis.items" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ capitalFirst(menu.m_produk_nama) }}</td>
                      <td class="text-right">
                        {{ menu.tmp_transaction_detail_qty }}
                      </td>
                      <td>
                        <span v-if="menu.tmp_transaction_detail_takeaway_status == 1">
                        (Take Away) 
                        </span>
                      {{ menu.tmp_transaction_detail_custom_menu }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <label for="">Waiter</label>
            <div class="form-group">
              <div class="row mx-n1">
                  <div class="col px-1">
                      <input type="text" v-model="waiterName" class="form-control" v-on:keyup="openKitchen()">
                  </div>
              </div>
            </div>
            <button id="close_modal_kitchen" type="button" class="btn btn-light" data-dismiss="modal">
              Batal
            </button>
            <button
              v-on:click="setToProduction()"
              type="button"
              class="btn btn-action"
              :disabled="kitchenButton"
            >
              Order ke Produksi
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Keluarkan Menu -->
    <div class="modal fade" id="modal-keluarkan-menu">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-accent text-white border-0">
            <h5 class="modal-title">
              Daftar menu
              <strong class="text-danger">Yang akan dikeluarkan</strong>
            </h5>
            <button
              type="button"
              class="close text-white"
              id="close-form-keluarkan-menu"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              class="form"
              id="form-keluarkan-menu"
              @submit.prevent="pullMenusAction()"
              method="post"
            >
              <div class="modal-body">
                <div
                  v-for="(menu, index) in pullMenus"
                  :key="index"
                  class="form-check mb-3 mt-1"
                >
                  <input
                    class="form-check-input"
                    :id="index"
                    type="checkbox"
                    :value="menu.m_transaction_menu_id"
                    name="m_transaction_menu_ids[]"
                  />
                  <label class="form-check-label" :for="index">
                    {{ menu.m_menu_nama }}
                    <span v-if="menu.custom_menu">
                      ( {{ menu.custom_menu }} )
                    </span>
                  </label>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-dismiss="modal"
                >
                  Batal
                </button>
                <button type="submit" class="btn btn-danger">
                  Keluarkan Menu
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Updated Nota-->
    <div class="modal fade" id="modal-update-transaction-nota">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white border-0">
                        <h5 class="modal-title">Update Info Nota</h5>
                        <button id="closeModalInformationUpdateForm" v-on:click="loadTable" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="form" id="transactionTableUpdateData" @submit.prevent="transactionTableUpdate" method="post">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="form-group">
                                        <label for="">Nomor Nota</label>
                                        <input type="hidden" name="_method" value="PUT">
                                        <input type="hidden" name="tmp_transaction_id" id="update_tmp_transaction_id" required readonly>
                                        <input type="text" class="form-control numeric" name="tmp_transaction_note_number" 
                                          id="update_tmp_transaction_note_number" required readonly
                                          tabindex="1" minlength="4" ref="nota_number">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Bigboss</label>
                                        <input type="text" class="form-control"  name="tmp_transaction_customer_name" 
                                          id="update_tmp_transaction_customer_name" required
                                          tabindex="2" minlength="3">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="row mx-n2">
                                      <div class="col-5 px-2">
                                          <div class="form-group">
                                              <label for="">Jam Order</label>
                                              <input class="form-control timepicki" 
                                                name="tmp_transaction_order_time" id="update_tmp_transaction_order_time" required
                                                tabindex="4">
                                          </div>
                                      </div>
                                      <div class="col-5 px-2">
                                          <div class="form-group">
                                              <label for="">Jam Menu Lengkap</label>
                                              <input class="form-control timepicki" 
                                                name="tmp_transaction_kitchen_done_time" id="update_tmp_transaction_kitchen_done_time" 
                                                tabindex="4">
                                                <span>*optional</span>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group">
                                      <label for="member">ID Member / NIK</label>
                                      <input type="text" class="form-control keyboard"
                                      id="update_tmp_transaction_member_id"  
                                        name="tmp_transaction_member_id" autocomplete="off" minlength="5">
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" 
                              data-dismiss="modal" tabindex="5">Batal</button>
                            <button type="submit" class="btn btn-accent" tabindex="6">Simpan</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    <section>
        <div class="modal fade" id="modal-input-tunai">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Tarik Tunai</h6>
                        <button id="close-modal-tunai" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <label for="">Masukkan Jumlah Uang</label>
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <input id="input_tarik_tunai" type="text" class="form-control numeric">
                                </div>
                                
                                    <div class="col-auto px-1">
                                        
                                        <button v-on:click="setTarikTunai()" class="btn btn-primary btn-confirm-cash">Simpan</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section>

    <section>
      <button id="show-modal-struk" data-toggle="modal" data-backdrop="static" data-target="#modal-struk"/>
        <div class="modal fade" id="modal-struk" data-backdrop="static">
          <button id="close-modal-struk" type="button" class="close" data-dismiss="modal" aria-label="Close"/>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title">Cetak/Kirim Struk</h6>
                        <!-- <button id="close-modal-struk" type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="toHome">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button> -->
                    </div>
                    <div class="modal-body py-4">
                      <div class="form-group">
                        <label for="">NIK/ID Member</label>
                        <div class="row mx-n1">
                          <div class="col px-1">
                            <input v-model="memberId" id="member_id" type="text" class="form-control numeric" minLength="5" v-on:keyup="searchMember()">
                          </div>
                          <button 
                          class="btn btn-accent"
                          type="button"
                          v-on:click="searchMember()"><i class="fa fa-search"></i>
                          </button>
                          <div class="col px-1">
                              <input type="text" class="form-control" v-model="memberName" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="">Nama Akun Pembayaran</label>
                        <div class="row mx-n1">
                          <div class="col px-1">
                            <input v-model="accountName" id="account_name" type="text" class="form-control" placeholder="Contoh: Mister Huhah" minLength="10">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="">Nomor HP</label>
                        <div class="row mx-n1">
                          <div class="col px-1">
                            <input v-model="nomorHp" id="nomor_hp" type="text" class="form-control numeric" placeholder="Contoh: 081765765243" minLength="10">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button v-on:click="struk(1,0)" class="btn btn-success" >Kirim WA</button>
                      <button v-on:click="struk(1,1)" class="btn btn-warning" >Cetak & Kirim WA</button>
                      <button v-on:click="struk(0,1)" class="btn btn-accent" >Cetak</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
      <button id="show-modal-input-multi-qty" data-toggle="modal" data-target="#modal-input-multi-qty"/>
        <div class="modal fade" id="modal-input-multi-qty">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-accent text-white">
                        <h6 class="modal-title" id="titleMultiQty">Nama Menu</h6>
                        <button id="close-modal-multi-qty" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="form-group">
                            <!-- <label for="" >Qty</label> -->
                            <div class="row mx-n1">
                                <div class="col px-1">
                                  <input id="id_multi_qty" type="hidden" class="form-control" >
                                    <input id="input_multi_qty" type="number" class="form-control numeric" min="1">
                                </div>
                                
                                    <div class="col-auto px-1">
                                        
                                        <button v-on:click="saveMultiQty()" class="btn btn-success">Simpan</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        
    </section>
  <!-- Send Data to Component Pay -->
    <PopupPay 
      :total="TotalNotaListMenu" 
      :currentRound="currentRound" 
      :transId="tmpId"
      :pajak="pajakNotaListMenu"
      :service="ServiceNotaListMenuPrice"
      :tunai="TarikTunaiNotaListMenu"
      :discount="DiskonKhusus"
      :defaultPay="defaultPay"
      :voucher="DiskonVoucher"
      :sub_total="subTotalNotaListMenu"
      :lossBill="lossBill"
      :nomorHp="nomorHp"
      :memberId="memberId"
      :splitPay="splitPay"
    @onPay="payAction"
    @update:memberId="value => memberId = value"
    />

    <PopupReduceMenu
      :menu="selectedReduceMenu"
      @onReduce="reduceMenuMultiple"
    />
    <Voucher 
      :modalShow="voucherShow"
      @on-hide="hideVoucherModal" 
    />
    <PopupDiskon
      :total="TotalNotaListMenu" 
      @saveDiskon="addDiscount"
    />
  </div>
</template>
<script lang="js" >
import { onMounted, ref, computed } from 'vue';
// import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
// import { useStore } from 'vuex'
// import order from '@/mixins/order';
import { useRouter} from 'vue-router'; //useRouter, useRoute 
import Nav from '@/components/secure/dashboard/Nav.vue';
import PopupPay from '@/components/secure/dashboard/PopupPay.vue';
import PopupDiskon from '@/components/secure/dashboard/PopupDiskon.vue';
import PopupReduceMenu from '@/components/secure/dashboard/PopupReduceMenu.vue';
import Voucher from '@/components/secure/dashboard/voucher/Voucher.vue';
import '@formatjs/intl-numberformat';
import { VMoney } from 'v-money3';
import {roundTo} from 'round-to'; //roundTo, roundToUp, roundToDown

export default {
  name: 'Order',
  components: {
    Nav,
    PopupPay,
    PopupReduceMenu,
    Voucher,
    PopupDiskon
  },
  data() {
    return {
      stockMenuList: [],
      stockMenuKategoriList: [],
      stockList: [],
      notaListMenu: [],
      selectedMenuJenisId: {
        m_jenis_produk_id: ''
      },
      currentPajak: 0,
      currentService: 0,
      currentRound: 'ya',
      subTotalNotaListMenu: 0,
      TotalNotaListMenu: 0,
      pajakNotaListMenu: 0,
      ServiceNotaListMenu: 0,
      ServiceNotaListMenuPrice: 0,
      TarikTunaiNotaListMenu: 0,
      DiskonVoucher: 0,
      DiskonKhusus: 0,
      editPrice: 0,
      showButtonSave: "",
      inputMenuId: "",
      inputNotaId: "",
      lossBill: 'show',
      notaDetailList: {
          tmp_transaction_table_list: 0,
          tmp_transaction_note_number: 0,
          tmp_transaction_customer_name: 0,
          tmp_transaction_by: "",
          tmp_transaction_table_name_list: "",
          service_charge: 0,
          pajak: 0,
          m_t_t_name: "",
          pembulatan: '',
      },
      cart:[],
      menuOnCart: {
          id: 0,
          name: "",
          items: []
      },
      menuJenisSelected: {
          id: 0,
          name: ""
      },
      warningMenu: [],
      selectedReduceMenu: {},
      customListMenu: [],
      pullMenus: [],
      showStructModal: false,
      voucherModal: false,
      voucherShow: false,
      // resetDiscount: false,
      tmpId: this.$route.params.transactionId,
      defaultPay: 'all',
      cashierRole: {},
      nomorHp: '',
      accountName: '',
      memberId: '',
      splitPay: 'no',
      kodeTransaksi: '',
      repeatStruk: 1,
      editMenuPrice: 0,
      config: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 0,
          // masked: false
      },
      typeTransaksiId: 1,
      timerHitung : null,
      addProduk : true,
      waroengInfo : {},
      currencyFormat : 'id-ID',
      currencyPrefix : 'IDR',
      productionStatus : 0,
      userRole: 'kasir',
      waiterName: '',
      kitchenButton: true,
      memberName: '',
      menuOnKitchen: 0,
      totalOld: 0,
      totalNew: 0,
      searchMenu: ''
    }
  },
  directives: {money: VMoney},
  mounted() {
    this.userRole = localStorage.getItem('user_role');

    axios.get('m-waroeng/waroeng-info')
      .then(resp => {
        this.waroengInfo = resp.data
        if(this.waroengInfo.currency == 'RM'){
          this.currencyFormat = 'ms-MY'
          this.currencyPrefix = 'MYR'
        }
      }).catch(err => console.log(err))
    // const route = useRoute()
    // const transId = route.params.transactionId
    // this.loadTransactionType(transId)
    // .then(resp => {
    //   })
    this.load()
    // this.setupRole()
    // console.log(this.cashierRole)
    window.setTimeout( "loadingComponentOrder()" ,750);
    document.getElementById("listTableNavigation").classList.add("active");
    if(this.$route.params.transactionId != undefined){
      // this.timerHitung = setInterval(() => {
      //   axios.get('/m-transaction-meja/correction/'+this.$route.params.transactionId).then(res => {
      //     this.loadMenuNota(this.$route.params.transactionId)
      //     this.countNotaPrice()
      //   }).catch ((err) => {
      //     console.log(err);
      //   })
      // }, 5000);
    }
  },
  // beforeRouteLeave(){
  //   clearInterval(this.timerHitung);
  // },
  // computed: {
    // ...mapGetters({
    //   warung: 'warung/warung',
    //   vendorName: 'order/vendorName',
    //   isOnlineOrder: 'order/isOnlineOrder',
    //   voucherValue: 'voucher/voucherValue',
    //   discount: 'voucher/discount',
    //   payTotal: 'voucher/payTotal'
    // }),
    // mdrGojek() {
    //   return this.warung.m_w_gojek
    // },
    // mdrGrab() {
    //   return this.warung.m_w_grab
    // },
    // taxValue() {
    //   return this.warung.m_pajak.m_pajak_value
    // }
  // },
  methods: {
    openKitchen(){
      if(this.waiterName.length >= 3){
        this.kitchenButton = false
      }else{
        this.kitchenButton = true
      }
    },
    correctionHitung(){
      axios.get('/m-transaction-meja/correction/'+this.$route.params.transactionId).then(res => {
        this.loadMenuNota(this.$route.params.transactionId)
        this.countNotaPrice()
      }).catch ((err) => {
        console.log(err);
      })
    },
    addMultiQty(tmp_transaction_detail_id,menu,oldQty,status){
      if (status == 0) {
        document.getElementById('show-modal-input-multi-qty').click();
        document.getElementById('titleMultiQty').innerText = menu;
        document.getElementById("input_multi_qty").value = oldQty;
        document.getElementById("id_multi_qty").value = tmp_transaction_detail_id;
      }
    },
    saveMultiQty(){

      const id = document.getElementById("id_multi_qty").value;
      const qty = document.getElementById("input_multi_qty").value;
      if(qty > 0 && qty != ''){
        const formData = new FormData()
        formData.append('tmp_transaction_detail_id', id)
        formData.append('tmp_transaction_detail_qty', qty)
        
        axios.post('/m-transaction-meja/update-multi-qty', formData).then(res => {
          if(res.status == 200)
          this.loadMenuNota();
          document.getElementById('close-modal-multi-qty').click();

        }).catch ((err) => {
          if (err.response) {
            console.log(err.response.data)
          }
        })
      }
    },
    onInputChange(input) {
      console.log(input)
      // this.input = input.target.value;
    },
    setupRole(){
      // const callAkses = JSON.parse(localStorage.role_akses)
      // this.cashierRole = callAkses
      axios.get('/akses-role')
      .then(resp => {
        if (resp.status == 200) {
          this.cashierRole = resp.data
        }
      })
      .catch(err => console.log(err))
    },
    capitalFirst(text) {
      // ubah kalimat menjadi array of words
      const words = text.split(' ')
      // ubah setiap kata menjadi kapital
      const capitalizedWords = words.map(word => word[0]?.toUpperCase() + word.slice(1) || '')
      // gabungkan kembali menjadi kalimat
      return capitalizedWords.join(' ')
    },
    currency(price,prefix){
      const nominal = parseFloat(price);
      const format = this.currencyFormat
      const pref = this.currencyPrefix
      // const prefView = (prefix != '') ? this.waroengInfo.currency : '';

      const rupiah = new Intl.NumberFormat(format, {
        style: 'currency', //decimal,currency,percent
        currency: pref,
      }).format(nominal);
      
      // return prefView+' '+rupiah;
      return rupiah;
    },
    updateNota(transactionId){
      axios.get('/tmp-transaction/'+transactionId).then(res => {
        document.getElementById("update_tmp_transaction_note_number").value = res.data.data.tmp_transaction_note_number
        document.getElementById("update_tmp_transaction_customer_name").value = res.data.data.tmp_transaction_customer_name
        document.getElementById("update_tmp_transaction_order_time").value = res.data.data.tmp_transaction_order_time
        document.getElementById("update_tmp_transaction_kitchen_done_time").value = res.data.data.tmp_transaction_kitchen_done_time
        document.getElementById("update_tmp_transaction_id").value = res.data.data.tmp_transaction_id
        document.getElementById("update_tmp_transaction_member_id").value = res.data.data.tmp_transaction_member_id
      }).catch ((err) => {
        console.log(err);
      })
    },
    transactionTableUpdate: function(e){
      e.preventDefault();
      const formIdTransaction = document.getElementById('transactionTableUpdateData');
      const idTransactionNota = document.getElementById('update_tmp_transaction_id');
      const formSubmitTableTransaction = new FormData(formIdTransaction);
      axios.post('/tmp-transaction/'+idTransactionNota.value, formSubmitTableTransaction).then(res => {
        // formIdTransaction.reset();
        document.getElementById('closeModalInformationUpdateForm').click();
        this.loadNotaDetail()
        // this.load()
      }).catch ((err) => {
        console.log(err);
      })
    },
    // loadTransactionType(id) {
    //   axios.get('type-transactions/all')
    //     .then(resp => {
    //       const result = resp.data.data
    //       let transactionName = ''
    //       result.forEach((val) => {
    //         if (val.m_t_t_id == id) {
    //           transactionName = val.m_t_t_name
    //         }
    //       })

    //       // this.$store.dispatch('order/setVendorName', transactionName)
    //       // this.$store.dispatch('order/setIsOnlineOrder', true)

    //       // return transactionName
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    load() {
      axios.get('/m-menu-jenis?tmp_id='+this.$route.params.transactionId).then(res => {
        this.stockMenuList = res.data
        // const elements = this.$refs.menuList.getElementsByTagName("li")
          
        this.loadAllMenu()
        this.loadNotaDetail()
      }).catch ((err) => {
        console.log(err)
      })
    },
    loadNotaDetail(idTrans = this.$route.params.transactionId) {
      axios.get('/tmp-transaction/' + idTrans)
      .then(res => {
        if (res.data == null || res.data.data.tmp_transaction_status == 2) {
            this.$router.push({name: 'dashboardPage'});
            return false
        }
        // if(res.data.data.m_transaction_table_id_parent != null){
        //     this.$router.push({name: 'splitMenuOrder', params: {transactionId: res.data.data.m_transaction_table_id_parent}});
        // }

        this.notaDetailList = {
          tmp_transaction_table_list: res.data.data.tmp_transaction_table_list,
          tmp_transaction_note_number: res.data.data.tmp_transaction_note_number,
          tmp_transaction_customer_name: res.data.data.tmp_transaction_customer_name,
          tmp_transaction_by: res.data.data.name,
          tmp_transaction_table_name_list: res.data.data.tmp_transaction_table_name_list,
          pajak: res.data.data.pajak,
          service_charge: res.data.data.service_charge,
          m_t_t_name: res.data.data.m_t_t_name,
          pembulatan: res.data.data.pembulatan
        }
        this.currentPajak = res.data.data.pajak;
        this.currentService = res.data.data.service_charge;
        this.currentRound = res.data.data.pembulatan.toLowerCase();
        
        if(res.data.data.m_t_t_group == 'reguler'){
          document.getElementById("listTableNavigation").classList.add("active");
          if(this.userRole == 'kasir'){
            document.getElementById("onlineOrderNavigation").classList.remove("active");
          }
        }
        if(res.data.data.m_t_t_group == 'ojol'){
          document.getElementById("listTableNavigation").classList.remove("active");
          if(this.userRole == 'kasir'){
            document.getElementById("onlineOrderNavigation").classList.add("active");
          }
        }
        switch (res.data.data.m_t_t_name) {
            case 'gojek':
                this.defaultPay = 'gopay';
                this.lossBill = 'hide';
                break;
            case 'grab':
                this.defaultPay = 'ovo';
                this.lossBill = 'hide';
                break;
            case 'maxim':
                this.defaultPay = 'all';
                break;
            case 'shopeefood':
                this.defaultPay = 'shopeepay';
                this.lossBill = 'hide';
                break;
            case 'grabmart':
                this.defaultPay = 'ovo';
                this.lossBill = 'hide';
                break;
            default:
                this.defaultPay = 'all';
                break;
        }  
        let cekMemberId = 2
        if(res.data.data.tmp_transaction_member_id){
          cekMemberId = res.data.data.tmp_transaction_member_id.split('.')
          this.searchMember()
          // console.log('cek no hap: '+cekMemberId.length)
          if (cekMemberId.length == 1) {
            if(res.data.data.tmp_transaction_member_id.length >= 10){
              this.nomorHp = res.data.data.tmp_transaction_member_id
              this.memberId = res.data.data.tmp_transaction_member_id
            }else if(res.data.data.tmp_transaction_member_id.length == 5){
              this.nomorHp = ''
              this.memberId = res.data.data.tmp_transaction_member_id
            }else{
              this.nomorHp = ''
              this.memberId = ''
            }
          }else{
            this.nomorHp = ''
            this.memberId = res.data.data.tmp_transaction_member_id
          }
        }
        
        // this.TarikTunaiNotaListMenu = parseFloat(res.data.data.m_transaction_menu_tarik_tunai)
        // this.ServiceNotaListMenu = parseFloat(res.data.data.m_transaction_menu_service_charge)
        this.loadMenuNota();

      }).catch ((err) => {
        alert('Some Error');
        console.log(err);
        
        this.$router.push({name: 'dashboardPage'});
      })
    },
    searhMenuBy(){
      const transactionId = this.$route.params.transactionId
      const keyword = this.searchMenu.toLowerCase()
      axios.get('/mmenu', { params: { transactionId: transactionId }}).then(res => {
        this.stockMenuKategoriList = []
        res.data.forEach(function (menu) {
          menu.type = "Normal"
          menu.config_sub_jenis_produks.forEach(function(kategori) {
            if(kategori.m_sub_jenis_produk_nama != null){
              if (kategori.m_sub_jenis_produk_nama == 'panas' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar pedas') {
                menu.type = 'hot'
              } else if (kategori.m_sub_jenis_produk_nama.toLowerCase() == 'es') {
                menu.type = 'Ice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'juice'){
                menu.type = 'juice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'tumis'){
                menu.type = 'bakar'
              }
            }
          })
        })

        const filterMenu = []
        res.data.forEach(function(menu) {
          if(keyword.length > 2){
            if (menu.m_produk_nama.indexOf(keyword) > -1) {
              filterMenu.push(menu)
            }
          }else{
            filterMenu.push(menu)
          }
        })
        
        this.stockList = filterMenu
        if (res.data.length > 0) {
          this.showButtonSave = ""
        } else {
          this.showButtonSave = "display:none"
        }

      }).catch ((err) => {
        console.log(err);
      })
    },
    loadAllMenu() {
      const transactionId = this.$route.params.transactionId

      axios.get('/mmenu', { params: { transactionId: transactionId }}).then(res => {
        this.stockMenuKategoriList = []
        res.data.forEach(function (menu) {
          menu.type = "Normal"
          menu.config_sub_jenis_produks.forEach(function(kategori) {
            if(kategori.m_sub_jenis_produk_nama != null){
              if (kategori.m_sub_jenis_produk_nama == 'panas' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar pedas') {
                menu.type = 'hot'
              } else if (kategori.m_sub_jenis_produk_nama.toLowerCase() == 'es') {
                menu.type = 'Ice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'juice'){
                menu.type = 'juice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'tumis'){
                menu.type = 'bakar'
              }
            }
          })
        })
        this.stockList = res.data
        if (res.data.length > 0) {
          this.showButtonSave = ""
        } else {
          this.showButtonSave = "display:none"
        }

      }).catch ((err) => {
        console.log(err);
      })
    },
    loadMenuList(menuJenis) {
      const transactionId = this.$route.params.transactionId
      // let mdrValue = 0.0
      // const vendorName = this.vendorName
      // if (vendorName == 'grab') {
      //   mdrValue = this.mdrGrab
      // } else if (vendorName == 'gojek') {
      //   mdrValue = this.mdrGojek
      // }
      // const isOnlineOrder = this.isOnlineOrder

      axios.get('/mmenu?m_produk_m_jenis_produk_id='+menuJenis.m_jenis_produk_id, {
        params: { transactionId: transactionId }
      })
      .then(res => {
        res.data.forEach(function (menu) {
          // if (isOnlineOrder) {
          //   const price = parseFloat(menu.m_menu_harga_nominal)
          //   menu.m_menu_harga_nominal = (price * mdrValue) + price
          // }

          menu.type = "Normal"
          menu.config_sub_jenis_produks.forEach(function(kategori) {
            if(kategori.m_sub_jenis_produk_nama != null){
              if (kategori.m_sub_jenis_produk_nama.toLowerCase() == 'panas' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar pedas') {
                menu.type = 'hot'
              } else if (kategori.m_sub_jenis_produk_nama.toLowerCase() == 'es') {
                menu.type = 'Ice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'juice'){
                menu.type = 'juice'
              }else if(kategori.m_sub_jenis_produk_nama.toLowerCase() == 'bakar' || kategori.m_sub_jenis_produk_nama.toLowerCase() == 'tumis'){
                menu.type = 'bakar'
              }
            }

          });
        })
        this.stockList = res.data
              
        if (res.data.length > 0) {
          this.showButtonSave = ""
        } else {
          this.showButtonSave = "display:none"
        }
      }).catch ((err) => {
        console.log(err);
      })
    },
    loadMenuByKategori(idKategori) {
      const transactionId = this.$route.params.transactionId
      axios.get('/m-menu/byKategori/' + idKategori, {
        params: { transactionId: transactionId }
      })
      .then(res => {
        res.data.forEach(function (menu) {
          if(menu.type != null){
            if (menu.type.toLowerCase() == 'panas' || menu.type.toLowerCase() == 'bakar pedas') {
              menu.type = 'hot'
            } else if (menu.type.toLowerCase() == 'es') {
              menu.type = 'Ice'
            }else if (menu.type.toLowerCase() == 'juice') {
              menu.type = 'juice'
            }else if (menu.type.toLowerCase() == 'bakar' || menu.type.toLowerCase() == 'tumis') {
              menu.type = 'bakar'
            }else{
              menu.type = "Normal"
            }
          }
        })
        this.stockList = res.data
        
        if (res.data.length > 0) {
          this.showButtonSave = ""
        } else {
          this.showButtonSave = "display:none"
        }
      }).catch ((err) => {
        console.log(err.response.data);
      })
    },
    loadKategoriList(menuJenis) {
      const transactionId = this.$route.params.transactionId
        axios.get('/m-kategori?m_sub_jenis_produk_m_jenis_produk_id='+menuJenis.m_jenis_produk_id,
          { params: { transactionId: transactionId }}).then(res => {
            
            const temp = []
            if (res.data.length>0) {
                
                res.data.forEach(element => {
                    
                    const e = JSON.parse(JSON.stringify(element));
                    temp.push({
                        m_sub_jenis_produk_id : e.m_sub_jenis_produk_id,
                        m_sub_jenis_produk_nama : e.m_sub_jenis_produk_nama,
                        m_sub_jenis_produk_m_jenis_produk_id: e.m_sub_jenis_produk_m_jenis_produk_id
                    })
                });

            }
            this.selectedMenuJenisId.m_jenis_produk_id = menuJenis.m_jenis_produk_id
            this.loadMenuList(menuJenis)
            this.stockMenuKategoriList = temp
            
        }).catch ((err) => {
            console.log(err);
        })
    },
    backToMenu(){
        this.$router.push({name: 'dashboardPage'});
    },
    saveTransaction(idMenuJenis = this.$route.params.transactionId){
        axios.put('/tmp-transaction/'+idMenuJenis, {
            tmp_transaction_detail_service_charge: this.ServiceNotaListMenu,
            tmp_transaction_detail_tarik_tunai: this.TarikTunaiNotaListMenu
        }).then(_res => {
            // clearInterval(this.timerHitung);
            this.$router.push({name: 'dashboardPage'});
        }).catch ((err) => {
            console.log(err);
        })
    },
    addMenutoNota(transactionMenuId, menuId, notaId) {
      this.addProduk = false
      const transactionId = this.$route.params.transactionId
      axios.post('/m-transaction-meja/add-menu', {
        tmp_transaction_detail_id: transactionMenuId,
        tmp_transaction_detail_m_produk_id: menuId,
        tmp_transaction_detail_tmp_transaction_id: notaId,
        tmp_transaction_detail_production_status: 0,
        transactionId: transactionId
      }).then(res => {
        if(res.status == 201 || res.status == 202){
          this.loadMenuNota(notaId)
          // this.countNotaPrice()
          this.addProduk = true
        }
          
      }).catch ((err) => {
        this.addProduk = true
        // console.log(err.response.data);
      })
    },
    reduceMenutoNota(menuId, notaId){
      this.addProduk = false
      // this.setupRole()
      // Cek Akses Before Execute
      axios.get('/akses-role')
      .then(resp => {
        if (resp.status == 200) {
          this.cashierRole = resp.data
          if (this.cashierRole.cancel_menu.defaultRole == 'deny' && this.cashierRole.cancel_menu.temporaryRole == 'deny') {
              this.$swal.fire({
                title:"Akses DiTolak!", 
                text:"Hubungi Kacab/Asisten", 
                icon:"warning",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
          }else{
            axios.post('/m-transaction-meja/reduce-menu', {
              tmp_transaction_detail_id: menuId,
              role_user: this.userRole
            }).then(res => {
                this.loadMenuNota(notaId)
                this.countNotaPrice()
                this.addProduk = true
            }).catch ((err) => {
                if (err.response.status == 404) {
                    this.$swal.fire({
                        title:"Error", 
                        text:err.response.data.message, 
                        icon:"warning",
                        showCancelButton: false,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Tutup'
                    })
                }
            })
          }
        }
      })
      .catch(err => console.log(err))
    },
    kroscekBayar(){
      this.totalOld = this.subTotalNotaListMenu
      this.loadMenuNota()
      if(this.totalOld > 0){
        if(this.totalNew != this.totalOld){
          this.$swal.fire({
              title:"Warning!", 
              text:"Terjadi perubahan data",
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            })
            .then(function (success) {
              if (success.isConfirmed) {
                document.getElementById('close-bayar').click()
              }
            })
        }
      }
    },
    loadMenuNota(idMenuJenis = this.$route.params.transactionId) {
      // let mdrValue = 0.0
      // let vendorName = this.vendorName
      // if (vendorName.trim() == '') {
      //   vendorName = this.notaDetailList.m_t_t_name.toLowerCase()
      // }

      // if (vendorName == 'grab') {
      //   mdrValue = this.mdrGrab
      // } else if (vendorName == 'gojek') {
      //   mdrValue = this.mdrGojek
      // }
      // let isOnlineOrder = this.isOnlineOrder
      // if (['grab', 'gojek'].indexOf(this.notaDetailList.m_t_t_name.toLowerCase()) > -1) {
      //   isOnlineOrder = true
      // }

      axios.get('/transaction-detail?tmp_transaction_detail_tmp_transaction_id=' + idMenuJenis)
      .then(res => {
        this.notaListMenu = res.data
        this.cart = []
        // this.notaListMenu.forEach((menu) => {
        //   if (isOnlineOrder) {
        //     const price = parseFloat(menu.m_transaction_menu_price)
        //     const priceAfterMarkup = (price * mdrValue) + price
        //     menu.m_transaction_menu_price = priceAfterMarkup
        //     menu.m_transaction_menu_tax =  priceAfterMarkup * this.taxValue
        //   } 
        // })

        if (res.data.length > 0) {
          const tempMenuJenis = []
          let searchMenuKitchen = 0
          res.data.forEach(function(e) {
            if (tempMenuJenis.indexOf(e.m_produk_m_jenis_produk_id) === -1) {
              tempMenuJenis.push(e.m_produk_m_jenis_produk_id)
            }
            
            if(searchMenuKitchen === 0){
              if(e.tmp_transaction_detail_production_status === 1){
                searchMenuKitchen = 1
              }
            }
          });
          this.menuOnKitchen = searchMenuKitchen
          
          const menuList = this.stockMenuList
          const notaList = this.notaListMenu
          const tempCart = []
          tempMenuJenis.forEach(function(e) {
              const tempMenuList = []
              let name
              menuList.forEach(function (item) {
                  
                  if (item.m_jenis_produk_id == e) {
                      name = item.m_jenis_produk_nama
                  }
              });
              notaList.forEach(function (item) {
                  if (e == item.m_produk_m_jenis_produk_id) {
                      tempMenuList.push(item)
                  }                                
              });

              const menuOnCart = {
                  id: e,
                  name: name,
                  items: tempMenuList
              }
              tempCart.push(menuOnCart)  
          });

          this.cart = tempCart;
        }
            
        this.countNotaPrice();
      }).catch ((err) => {
        console.log(err.response);
      })
    },
    setNullPajak(){
        this.currentPajak = 0
        this.countNotaPrice()
    },
    setPajak(){
        this.currentPajak = this.notaDetailList.pajak
        this.countNotaPrice()
    },
    setNullService(){
        this.currentService = 0
        this.countNotaPrice()
    },
    setService(){
        this.currentService = this.notaDetailList.service_charge
        this.countNotaPrice()
    },
    countNotaPrice(){
        let total = 0
        let pajak = 0
        let serviceCharge = 0
        let taxControl = 0
        let serviceControl = 0
        let subTotControl = 0
        const currentPajak = this.currentPajak
        const currentService = this.currentService

        for (let i = 0; i < this.notaListMenu.length; i++) {
            const item = JSON.parse(JSON.stringify(this.notaListMenu[i]));
            // console.log(item)
            if (item.tmp_transaction_detail_status==0) {
              total += item.tmp_transaction_detail_nominal
              subTotControl += item.tmp_transaction_detail_qty*item.tmp_transaction_detail_price
              if(item.tmp_transaction_detail_service_charge_status && currentService>0){                
                serviceCharge += item.tmp_transaction_detail_service_charge
                serviceControl += (item.tmp_transaction_detail_qty*item.tmp_transaction_detail_price)*item.tmp_transaction_detail_service_val
              }
              if(item.tmp_transaction_detail_tax_status && currentPajak>0){ 
                pajak += item.tmp_transaction_detail_tax
                taxControl += (item.tmp_transaction_detail_qty*item.tmp_transaction_detail_price)*item.tmp_transaction_detail_tax_val
              }
            }
            
        }
        this.TotalNotaListMenu = 0
        // console.log("total:",roundTo(total,2))
        // console.log("subTotControl:",roundTo(subTotControl,2))
        // console.log("serviceCharge:",roundTo(serviceCharge,2))
        // console.log("serviceControl:",roundTo(serviceControl,2))
        // console.log("pajak:",roundTo(pajak,2))
        // console.log("taxControl:",roundTo(taxControl,2))


        if (roundTo(total,2) != roundTo(subTotControl,2) || roundTo(serviceCharge,2) != roundTo(serviceControl,2) || roundTo(pajak,2) != roundTo(taxControl,2)) {

          this.addProduk = false
          this.correctionHitung()
          this.addProduk = true
        } else {
          this.subTotalNotaListMenu = total;
          this.ServiceNotaListMenuPrice = serviceCharge
          this.pajakNotaListMenu = pajak;
          this.TotalNotaListMenu = this.pajakNotaListMenu + this.ServiceNotaListMenuPrice + this.subTotalNotaListMenu + this.ServiceNotaListMenu + this.TarikTunaiNotaListMenu - this.DiskonKhusus - this.DiskonVoucher

          // clearInterval(this.timerHitung);
        }
        
        //Kroscek on Bayar
        this.totalNew = this.subTotalNotaListMenu
        // if(this.totalOld > 0){
        //   if(this.totalNew != this.totalOld){
        //     this.$swal.fire({
        //         title:"Warning!", 
        //         text:"Terjadi perubahan data", 
        //         icon:"warning",
        //         showCancelButton: false,
        //         confirmButtonColor: '#3085d6',
        //         confirmButtonText: 'Ok'
        //       })
        //       .then(function (success) {
        //         if (success.isConfirmed) {
        //           document.getElementById('close-bayar').click()
        //         }
        //       }) 
        //   }else{
        //     console.log("gagal validasi")
        //   }
        // }
    },
    setServiceCharge(){
        this.ServiceNotaListMenu
        const promptServiceCharge = prompt("Masukan Persentase Service Charge", this.ServiceNotaListMenu);
        if (promptServiceCharge != null && parseFloat(promptServiceCharge)) {
            this.ServiceNotaListMenu = parseFloat(promptServiceCharge)
            this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
        }else{
            this.ServiceNotaListMenu = parseFloat(0)
            this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
        }
        this.TotalNotaListMenu = 0;
        this.TotalNotaListMenu += this.subTotalNotaListMenu;
        this.TotalNotaListMenu += this.pajakNotaListMenu;
        this.TotalNotaListMenu += this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
        this.TotalNotaListMenu += this.TarikTunaiNotaListMenu;
    },
    setTarikTunai(){
      const InputTarikTunai = document.getElementById("input_tarik_tunai").value
      this.TarikTunaiNotaListMenu
      // const promptTarikTunai = prompt("Masukan Jumlah Nominal Tarik Tunai", this.TarikTunaiNotaListMenu);
      if (InputTarikTunai != null && parseFloat(InputTarikTunai)) {
          this.TarikTunaiNotaListMenu = parseFloat(InputTarikTunai)
      }else{
          this.TarikTunaiNotaListMenu = parseFloat(0)
      }
      this.countNotaPrice()
      // this.TotalNotaListMenu = 0;
      // this.TotalNotaListMenu += this.subTotalNotaListMenu;
      // this.TotalNotaListMenu += this.pajakNotaListMenu;
      // this.TotalNotaListMenu += this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
      // this.ServiceNotaListMenuPrice = this.subTotalNotaListMenu / 100 * this.ServiceNotaListMenu;
      // this.TotalNotaListMenu += this.TarikTunaiNotaListMenu;
      document.getElementById("close-modal-tunai").click()
    },
    customMenu(menu){
      document.getElementById("titleCustomMenu").innerText = menu.m_produk_nama.toUpperCase()
      document.getElementById("tmp_transaction_detail_id_custom").value = menu.tmp_transaction_detail_id
      document.getElementById("tmp_transaction_detail_qty_custom").value = menu.tmp_transaction_detail_qty
      document.getElementById("tmp_transaction_detail_qty_custom").max = menu.tmp_transaction_detail_qty
      document.getElementById("tmp_transaction_detail_custom_menu_custom").value = menu.tmp_transaction_detail_custom_menu
      const taStatus = document.getElementById("tmp_transaction_detail_takeaway_status_custom")
      taStatus.checked = (menu.tmp_transaction_detail_takeaway_status == 1) ? true : false;
      
      document.getElementById("show-modal-custom-menu").click()

    },
    editMenu(menu){
      document.getElementById("titleEditMenu").innerText = menu.m_produk_nama.toUpperCase()

      // this.setupRole()
      // Cek Role
      axios.get('/akses-role')
      .then(resp => {
        if (resp.status == 200) {
          this.cashierRole = resp.data
          if (this.cashierRole.edit_menu.defaultRole == 'deny' && this.cashierRole.edit_menu.temporaryRole == 'deny') {
              this.$swal.fire({
                title:"Akses DiTolak!", 
                text:"Hubungi Kacab/Asisten", 
                icon:"warning",
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
          }else{
            document.getElementById("show-modal-edit-menu").click()
            const inputMenuIdClass = document.getElementsByClassName("inputMenuId");
            for (let i = 0; i < inputMenuIdClass.length; i++) {
              inputMenuIdClass[i].value = menu.tmp_transaction_detail_m_produk_id
            }
            const inputNotaIdClass = document.getElementsByClassName("inputNotaId");
            for (let i = 0; i < inputNotaIdClass.length; i++) {
              inputNotaIdClass[i].value = menu.tmp_transaction_detail_tmp_transaction_id
            }
            const transactionMenuClass = document.getElementsByClassName("transactionMenuId");
            for (let i = 0; i < transactionMenuClass.length; i++) {
              transactionMenuClass[i].value = menu.tmp_transaction_detail_id
            }
            const customMenuClass = document.getElementsByClassName("editCustomMenu");
            for (let i = 0; i < customMenuClass.length; i++) {
              customMenuClass[i].value = menu.tmp_transaction_detail_custom_menu
            }

            this.loadCustomMenu(menu)
            
            const menuDetail = JSON.parse(JSON.stringify(menu))
            const scMenuModalEl = document.getElementById("service_charge_edit_menu_modal")
            scMenuModalEl.checked = menuDetail.tmp_transaction_detail_service_charge_status
            const pajakMenuModalEl = document.getElementById("pajak_edit_menu_modal")
            pajakMenuModalEl.checked = menuDetail.tmp_transaction_detail_tax_status
            
            const formDiscount = document.getElementById("tmp_transaction_detail_discount_edit_menu_modal")
            
            let discountType = menuDetail.tmp_transaction_detail_discount_type

            if (discountType == 'NormalPrice') {
              discountType = 'CustomPrice'
            }
            document.getElementById("tmp_transaction_detail_discount_type_edit_menu_modal").value = menuDetail.tmp_transaction_detail_discount_type
            
            if (discountType == 'CustomPrice') {
              const discount = menuDetail.tmp_transaction_detail_price
              formDiscount.value = discount
            } else {
              formDiscount.value = menuDetail.tmp_transaction_detail_discount
            }

            this.editPrice = menuDetail.m_menu_harga_nominal * menuDetail.tmp_transaction_detail_qty
          }
        }
      })
      .catch(err => console.log(err))
    },
    garansiMenu(menuId, notaId, menutTransactionId, produkName){
        document.getElementById("titleCancelMenu").innerText = produkName.toUpperCase()
        const inputMenuIdClass = document.getElementsByClassName("inputMenuId");
        for (let i = 0; i < inputMenuIdClass.length; i++) {
            inputMenuIdClass[i].value = menuId
        }
        const inputNotaIdClass = document.getElementsByClassName("inputNotaId");
        for (let i = 0; i < inputNotaIdClass.length; i++) {
            inputNotaIdClass[i].value = notaId
        }
        const transactionMenuClass = document.getElementsByClassName("transactionMenuId");
        for (let i = 0; i < transactionMenuClass.length; i++) {
            transactionMenuClass[i].value = menutTransactionId
        }
        // axios.get('/m-transaction-meja/'+menutTransactionId).then(res => {
        //     console.log(res.data)
        // }).catch ((err) => {
        //     console.log(err);
        // })
    },
    setPembatalanMenu(){
        const elementsGaransi = document.getElementsByClassName("garansi-menu-tab");
        for (let i = 0; i < elementsGaransi.length; i++) {
            elementsGaransi[i].value = ""
        }
    },
    setGaransiMenu(){
        // garansi-menu-tab
        const elementsPembatalan = document.getElementsByClassName("pembatalan-menu-tab");
        for (let i = 0; i < elementsPembatalan.length; i++) {
            elementsPembatalan[i].value = ""
        }
    },
    transactionGaransiMenu: function(idForm){
        const formIdTransaction = document.getElementById(idForm);
        const formSubmitTableTransaction = new FormData(formIdTransaction);
        for (const pair of formSubmitTableTransaction.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        axios.post('/m-transaction-meja/changeMenuDetail', formSubmitTableTransaction).then(res => {
            console.log(res);
            if (res.data.meta.code==200) {
                formIdTransaction.reset();
                this.loadMenuNota();
                document.getElementById('closeModalGaransi').click();    
            } else if (res.data.meta.code==400) {
                this.$swal.fire({
                    title:"Error", 
                    text:res.data.meta.message, 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Tutup'
                })
            }
            
        }).catch ((err) => {
            console.log(err.response.data);
        })
    },
    transactionCustomMenu: function(idForm){
      const takeBox = document.getElementById("tmp_transaction_detail_takeaway_status_custom")
      if (takeBox.checked) {
        takeBox.value = 1
      }else{
        takeBox.value = 0
      }

      const formIdTransaction = document.getElementById(idForm);
      const formSubmitTableTransaction = new FormData(formIdTransaction);

      axios.post('/m-transaction-meja/customMenu', formSubmitTableTransaction).then(res => {
            
            console.log(res);
            
            document.getElementById('close-custom-modal').click()
            if (res.data.meta.code==200) {
                this.loadMenuNota();
            } else {
                this.$swal.fire({
                    title:"Error", 
                    text:res.data.meta.message, 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Tutup'
                })
            }
            
        }).catch ((err) => {
            console.log(err.response.data);
        })
    },
    transactionEditMenu: function(idForm){

        const scBox = document.getElementById("service_charge_edit_menu_modal")
        const taxBox = document.getElementById("pajak_edit_menu_modal")

        if (scBox.checked) {
            scBox.value = this.editPrice * this.currentService
        }

        if (taxBox.checked) {
            taxBox.value = this.editPrice * this.currentPajak
        }

        const formIdTransaction = document.getElementById(idForm);
        const formSubmitTableTransaction = new FormData(formIdTransaction);

        for (const pair of formSubmitTableTransaction.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        axios.post('/m-transaction-meja/changeMenuDetail', formSubmitTableTransaction).then(res => {
            
            console.log(res);
            
            if (res.data.meta.code==200) {
                // console.log(res.data.data);
                
                // Jangkrik Non aktif custom menu
                // this.updateMenus(formSubmitTableTransaction)
                document.getElementById('close-edit-modal').click()
                this.loadMenuNota();
            } else {
                this.$swal.fire({
                    title:"Error", 
                    text:res.data.meta.message, 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Tutup'
                })
            }
            
        }).catch ((err) => {
            console.log(err.response.data);
        })
    },
    updateMenus(formSubmit){
        formSubmit.delete('tmp_transaction_detail_type')
        formSubmit.delete('tmp_transaction_detail_m_produk_id')
        formSubmit.delete('tmp_transaction_detail_tmp_transaction_id')
        formSubmit.delete('tmp_transaction_detail_id')
        formSubmit.delete('tmp_transaction_detail_discount')
        formSubmit.delete('tmp_transaction_detail_price')

        for (const pair of formSubmit.entries()) {
            if (pair[1] == '') {
                pair[1] = '[delete]'
            }
        }
        
        axios.post('/m-transaction-menu/update-menus', formSubmit).then(res => {
            
            document.getElementById('close-edit-modal').click()
            this.loadMenuNota();
            document.getElementById('closeModalGaransi').click();
            
        }).catch(err => {
            console.log(err.response.data);
            
        })

    },
    deleteTransaction(transId = this.$route.params.transactionId){
      // this.setupRole()
      const route = this.$router;
      const swal = this.$swal;
      // Cek akses berfore execute
      axios.get('/akses-role')
      .then(resp => {
        if (resp.status == 200) {
          this.cashierRole = resp.data

          if (this.cashierRole.cancel_transaksi.defaultRole == 'deny' && this.cashierRole.cancel_transaksi.temporaryRole == 'deny') {
            this.$swal.fire({
              title:"Akses DiTolak!", 
              text:"Hubungi Kacab/Asisten", 
              icon:"warning",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            })
          }else{
            this.$swal.fire({
              title:"Konfirmasi", 
              text:"Yakin mau hapus pesanan meja?", 
              icon:"warning",
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Iya',
              cancelButtonText: 'Tidak'
            }).then(function (success) {
                if (success.isConfirmed) {
                    axios.delete('/tmp-transaction/'+transId).then(res => {
                      if (res.data.error == 0) {
                        route.push({name: 'dashboardPage'});
                      }
                    }).catch ((err) => {
                        if (err.response.status==404) {
                            swal.fire({
                                title:"Error", 
                                text:err.response.data.message, 
                                icon:"warning",
                                showCancelButton: false,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'Tutup'
                            })
                        }
                    })
                }
            })
          }
        }
      })
      .catch(err => console.log(err))
    },
    splitMenuOrder(transsId = this.$route.params.transactionId) {
      this.$router.push({name: 'splitMenuOrder', params: { transactionId: transsId}});
    },
    setWarningMenu(){
      const allMenu = this.notaListMenu
      const allJenis = this.stockMenuList
      const newWarningMenu = []
      const allWarningMenu = []

      allJenis.forEach(function (jenis) {
        const newItems = []
        const allItem = []
        allMenu.forEach(function(menu) {
          if (menu.m_produk_m_jenis_produk_id == jenis.m_jenis_produk_id 
          ) {
            if (menu.tmp_transaction_detail_production_status == 0){
              newItems.push(menu)
            }else{
              allItem.push(menu)
            }
          }
        })

        if (newItems.length > 0) {
          newWarningMenu.push({
              name: jenis.m_jenis_produk_nama,
              items: newItems
          })
        }

        if (allItem.length > 0) {
          allWarningMenu.push({
              name: jenis.m_jenis_produk_nama,
              items: allItem
          })
        }
      })
      if (newWarningMenu.length > 0){
        this.warningMenu = newWarningMenu
        this.productionStatus = 0
      } else {
        this.warningMenu = allWarningMenu
        this.productionStatus = 1
      }
    },
    setToProduction: function(){
      const formData = new FormData()
      formData.append('tmp_transaction_id', this.$route.params.transactionId)
      formData.append('production_status', this.productionStatus)
      formData.append('waiter_name', this.waiterName)

      this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Pesanan...',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          axios.post('/cashier/print-kitchen', formData).then(res => {
            console.log(res.data)
            this.loadMenuNota()
            document.getElementById("close_modal_kitchen").click()
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('bill success')
        }
      })
      // axios.get('/m-transaction-meja/set-production/'+this.$route.params.transactionId)
      // .then(res => {
      //   console.log(res)
      //   if (res.status == 202) {
      //     document.getElementById("close-warning").click()
      //     this.loadMenuNota()
      //   }
          
      // }).catch ((err) => {
      //   if (err.response) {
      //     console.log(err.response.data)
      //   }
      // })
    },
    loadCustomMenu(menu){
        axios.post('/m-transaction-menu/list-menu', {
            tmp_transaction_detail_m_produk_id: menu.tmp_transaction_detail_m_produk_id,
            tmp_transaction_detail_tmp_transaction_id: menu.tmp_transaction_detail_tmp_transaction_id
        }).then(res => {
            // console.log(res);
            const newListMenu = []
            if (res.data.meta.code ==200) {
                res.data.data.forEach(function(menu) {
                    for (let i = 0; i < menu.tmp_transaction_detail_qty; i++) {
                        newListMenu.push(menu)
                    }
                });
            }

            this.customListMenu = newListMenu
            
        })
        window.setTimeout( "loadingComponentOrder()" ,750);
    },
    addDiscount(value){
      console.log(value.discountType)
      if (value.discountType == 'voucher') {
        this.DiskonVoucher = value.discountNominal
        this.countNotaPrice()
      }else{
        this.DiskonKhusus = value.discountNominal
        this.countNotaPrice()
      }
    },
    cetakRepeatPesanan(){
        const transId = this.$route.params.transactionId
        this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Pesanan',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          axios.get('/cashier/print-repeat-order/'+transId).then(res => {
            if(res.data.struct != ''){
                location.href = res.data.struct
            }
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('bill success')
        }
      })
    },
    searchMember(){
      const member = this.memberId;
      
      if(member){
          const clearMmber = member.replace ( /[^0-9.]/g, '' );
          if(clearMmber.split('.').length == 1){
            if(clearMmber.length == 5){
                const userId = parseInt(clearMmber)
                if(userId > 0){
                    axios.get('/users/'+userId)
                    .then(resp => {
                        console.log(resp)
                        if (resp.status == 200) {
                            this.memberName = resp.data.data.name
                        }else{
                            this.memberName = 'ID Tidak Valid'
                        }
                    })
                    .catch(err => console.log(err))
                }else{
                    this.memberName = 'ID Tidak Valid'
                }
            }else{
                this.memberName = 'ID Tidak Valid'
            }
          }
      }
    },
    strukCetak(gohome, repeat){
      const rekapTransId = this.kodeTransaksi
      // const repeat = this.repeatStruk
      this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Struk',
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          // console.log(resp);
          axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
            // console.log(resp.data)
            if(resp.data.error == 0){
              if(resp.data.struct != ''){
                location.href = resp.data.struct
              }
            }
            
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('printing success')
        }
        document.getElementById('close-modal-struk').click()
        if (gohome == 1) {
          this.$router.push({ name: 'dashboardPage' })
        }
      })
    },
    cronJobWa(target,transId){
      axios.get(`/cashier/cronjob-wa/${target}/${transId}`).then(resp => {
        console.log(resp)
      }).catch ((err) => {
        if (err.response) {
          console.log(err.response.data)
        }
      });
    },
    sendWa(target,transId){
      axios.get(`/sendStruct/${target}/${transId}`).then(resp => {
        console.log(resp)
      }).catch ((err) => {
        if (err.response) {
          console.log(err.response.data)
        }
      });
    },
    struk(wa,print){
      const rekapTransId = this.kodeTransaksi
      // const repeat = this.repeatStruk
      const hpbos = document.getElementById('nomor_hp').value.replace ( /[^0-9.]/g, '' );
      const member = document.getElementById('member_id').value;

      if(hpbos && hpbos.split('.').length == 1){
          if (hpbos.length < 10) {
              this.$swal.fire({
                  title:"Warning!", 
                  text:"Nomor WA Tidak Sesuai", 
                  icon:"warning",
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
              })
              return false
          }
      }

      if(member){
        const clearMember = member.replace ( /[^0-9.]/g, '' );
          if(clearMember.split('.').length == 1){
            let alert = 0
            if(member.length == 5){
                alert = 0
            }else{
                if (member.length < 10) {
                    alert = 1
                }
            }
            if (alert == 1) {
                this.$swal.fire({
                    title:"Warning!", 
                    text:"NIK/ID Member Tidak Sesuai", 
                    icon:"warning",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
                return false
            }
          }
      }

      const formData = new FormData()
      formData.append('r_t_id', rekapTransId)
      formData.append('nomor_wa', hpbos)
      formData.append('member_id', member)
      formData.append('account_name', this.accountName)
      axios.post('/transaksi/save-member', formData).then(res => {
          console.log(res);
      }).catch ((err) => {
          console.log(err.response.data);
      })

      if (wa == 1) {
        if (hpbos && hpbos.split('.').length == 1) {
          this.$swal.fire({
            // title: 'Mencetak!',
            html: 'Mengirim Pesan Ke '+hpbos,
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              this.$swal.showLoading()
              // console.log(resp);
              this.cronJobWa(hpbos,rekapTransId)
            }
          }).then((result)=>{
            if (print == 1) {
              this.strukCetak(1,1)

              // if (this.typeTransaksiId > 2) {
              //   this.strukCetak(1,1)
              // }else{
              //   this.strukCetak(1,2)
              // }
            }else{
              document.getElementById('close-modal-struk').click()
              this.$router.push({ name: 'dashboardPage' })
            }
          })
        }
      }else{
        if (this.typeTransaksiId > 2) {
          this.strukCetak(1,1)
        }else{
          this.strukCetak(1,2)
        }
      }
      
      // document.getElementById('close-modal-struk').click()
      // this.$router.push({ name: 'dashboardPage' })
    },
    toHome(){
      this.$router.push({ name: 'dashboardPage' })
    },
    payAction(value) {
      // clearInterval(this.timerHitung);

      let freeExchange = 0
      if (value.freeExchange != '') {
        freeExchange = value.freeExchange
      }
      let nomorWa = ''
      let memberId = ''
      if (value.paymentMethod == 1) {
        nomorWa = value.nomorWa
        memberId = value.memberId
      }
      let cetakStruk = 0
      if (value.cetakStruk != 0) {
        cetakStruk = value.cetakStruk
      }
      
      const formData = new FormData()
      formData.append('tmp_transaction_id', this.$route.params.transactionId)
      formData.append('payment_method', value.paymentMethod)
      formData.append('sub_total', this.subTotalNotaListMenu)
      formData.append('total', this.TotalNotaListMenu)
      formData.append('nominal_uang', value.payNominal)
      formData.append('exchange', value.exchange)
      formData.append('round', value.exchangeRounded)
      formData.append('free_exchange', freeExchange)
      formData.append('global_discount', this.DiskonKhusus)
      formData.append('nominal_voucher', this.DiskonVoucher)
      formData.append('nominal_pajak', this.pajakNotaListMenu)
      formData.append('nominal_sc', this.ServiceNotaListMenuPrice)
      formData.append('nominal_tariktunai', this.TarikTunaiNotaListMenu)
      formData.append('nomor_wa', nomorWa)
      formData.append('member_id', memberId)
      formData.append('cetak_struk', cetakStruk)
      formData.append('selisih', value.selisih)
      
      // BUG: habis cetak struk kadang2 data di dashboard tidak keluar
      //      kena cors
      axios.post('/recap-transactions', formData).then(res => {
        console.log(res.data.meta);
        if(res.data.meta.error == true){
          this.$swal.fire({
            title:"Terjadi Kesalahan!", 
            text:res.data.meta.message, 
            icon:"warning",
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
          .then(function (success) {
            if (success.isConfirmed) {
              document.getElementById('close-modal-exchange').click()
            }
          }) 
        }
        
        if (res.status == 201) {
          const resp = res.data.data
          // this.downloadStructFile(resp.struct_name)
          // console.log(resp)
          // this.$router.push({ name: 'dashboardPage' })
          this.$store.dispatch('voucher/setVoucherValue', 0)
          this.$store.dispatch('voucher/setDiscount', 0)

          const rekapTransId = resp.rekap_id
          this.kodeTransaksi = rekapTransId
          this.typeTransaksiId = resp.m_t_t_id

          if (value.paymentMethod > 1) {
            document.getElementById('closeChooseEdc').click()
            document.getElementById('show-modal-struk').click()
          }
          if (value.paymentMethod == 1) {
            // this.repeatStruk = 2
            if (nomorWa != '') {
              // this.repeatStruk = 1
              this.$swal.fire({
                // title: 'Mencetak!',
                html: 'Mengirim Pesan Ke '+nomorWa,
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                  this.$swal.showLoading()
                  this.cronJobWa(nomorWa,rekapTransId)
                }
              })
            }
            if (value.cetakStruk == 1) {
                if (nomorWa != '') {
                  this.strukCetak(0,1)
                }else{
                  this.strukCetak(0,2)
                }
            }
          }
          // if (value.paymentMethod > 1) {
          //   this.repeatStruk = 3
          // }

          // document.getElementById('close-modal-exchange').click()
          
          // this.$swal.fire({
          //   // title: 'Mencetak!',
          //   html: 'Mencetak Struk',
          //   timer: 3000,
          //   timerProgressBar: true,
          //   didOpen: () => {
          //     this.$swal.showLoading()
          //     // console.log(resp);
          //     axios.get(`/cashier/print-transaksi/${rekapTransId}/${repeat}`).then(resp => {
          //       // console.log(resp.data)
          //       if(resp.data != ''){
          //         location.href = resp.data
          //       }
          //       document.getElementById('close-modal-exchange').click()
          //       document.getElementById('closeChooseEdc').click()
          //     }).catch ((err) => {
          //       if (err.response) {
          //         console.log(err.response.data)
          //       }
          //     })
          //   }
          // }).then((result) => {
          //   /* Read more about handling dismissals below */
          //   if (result.dismiss === this.$swal.DismissReason.timer) {
          //     console.log('printing success')
          //   }
          // })
        }

      }).catch ((err) => {
        if (err.response) {
          console.log(err.response.data)
        }
      })
    },
    printBill() {
      const formData = new FormData()
      formData.append('tmp_transaction_id', this.$route.params.transactionId)
      formData.append('sub_total', this.subTotalNotaListMenu)
      formData.append('total', this.TotalNotaListMenu)
      formData.append('nominal_pajak', this.pajakNotaListMenu)
      formData.append('nominal_sc', this.ServiceNotaListMenuPrice)
      this.$swal.fire({
        // title: 'Mencetak!',
        html: 'Mencetak Struk',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          axios.post('/cashier/print-bill', formData).then(res => {
            if(res.data.struct != ''){
                location.href = res.data.struct
            }
          }).catch ((err) => {
            if (err.response) {
              console.log(err.response.data)
            }
          })
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('bill success')
        }
      })
    },
    // completeTransaction(){
    
    //     const formData = new FormData()
    //     formData.append('tmp_transaction_id',this.$route.params.transactionId)
    //     formData.append('nominal',this.TotalNotaListMenu)
    //     formData.append('exchange',0)
    //     formData.append('free_exchange',0)

        
    //     axios.post('/recap-transactions', formData).then(res => {
    //         if(res.status==201){
    //             document.getElementById("close-modal-exchange").click()
    //             this.$router.push({name: 'dashboardPage'});
    //         }
            
    //     }).catch ((err) => {
    //         console.log(err.response.data);
    //     })
    // },
    reduceMenuMultiple(value){
    
        const formData = new FormData()
        formData.append('m_transaction_menu_id', value.transactionMenuId)
        formData.append('qty',value.qty)
        formData.append('reason',value.reason)

        axios.post('/m-transaction-meja/reduce-menu-multiple', formData).then(res => {
            if(res.status==200){
                
                this.loadMenuNota()
            }
            
        }).catch ((err) => {
            console.log(err.response.data);
        })
        
    },
    getPullMenu(){
        axios.get('/m-transaction-table/get-pull-menu/'+this.$route.params.transactionId).then(res => {

            if (res.data.meta.code==200) {
                this.pullMenus = res.data.data
            }

        }).catch ((err) => {
            console.log(err.response.data);
            
        })
    },
    pullMenusAction() {
        const formKeluarkanMenu = document.getElementById('form-keluarkan-menu');
        const formDataKeluarkanMenu = new FormData(formKeluarkanMenu);

        for (const pair of formDataKeluarkanMenu.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        axios.post('/m-transaction-table/pull-menu', formDataKeluarkanMenu).then(res => {

            console.log(res);
            
            if (res.data.meta.code==200) {
                document.getElementById('close-form-keluarkan-menu').click()
                // formKeluarkanMenu.reset()
                this.loadMenuNota()
            }

        }).catch ((err) => {
            console.log(err.response.data);
            
        })
    },
    // Handle button `Lainnya`
    othersHandle() {
      if (this.cart.length === 0) {
        alert('Tambahkan menu terlebih dulu!')
        return false
      }

      this.voucherShow = true
      // this.$store.dispatch('voucher/showVoucherModal')
      this.$store.dispatch('voucher/setPayTotal', this.TotalNotaListMenu)
      // this.$store.dispatch('modal/showModal')
    },
    hideVoucherModal() {
      this.voucherShow = false
      // this.$store.dispatch('voucher/hideVoucherModal')
      // this.$store.dispatch('modal/hideModal')
    },
    removeDiskonKhusus(){
      this.DiskonKhusus = 0;
      this.countNotaPrice()
    },
    removeDiskonVoucher(){
      this.DiskonVoucher = 0;
      this.countNotaPrice()
    },
    removeTarikTunai(){
      this.TarikTunaiNotaListMenu = 0;
      this.countNotaPrice()
    },
    // removeDiscount() {
    //   if (!this.resetDiscount) {
    //     this.resetDiscount = true
    //     this.TotalNotaListMenu = parseFloat(this.TotalNotaListMenu) + parseFloat(this.discount)
    //     this.$store.dispatch('voucher/setDiscount', 0)
    //   }
    // }
  },
  // watch: {
  //   voucherValue: function(newVal, oldValue) {
  //     if (newVal > 0) {
  //       this.TotalNotaListMenu = this.payTotal
  //     }
  //   },
  //   discount: function(newVal, oldValue) {
  //     if (newVal > 0) {
  //       this.TotalNotaListMenu = this.payTotal
  //     }
  //   }
  // },
  setup() {
    const user = ref(null)
    const router = useRouter()
    // const store = useStore()
 
    // const { downloadStructFile } = order();
    onMounted( async () => {
      try {
        const response = await axios.get('/user');
        user.value = response.data.data;
      } catch (e) {
        await router.push({ name: 'loginPage' });
      }
    });

    // const setOnlineOrderStatus = (to) => {
    //   const id = to.params.transactionId
    //   store.dispatch('order/setTransactionType', id)
    // }

    return {
      user,
      // downloadStructFile,
      // setOnlineOrderStatus
    }
  },
  beforeRouteLeave(to, from, next){
    // clearInterval(this.timerHitung)
    next()
  }
  // beforeRouteEnter(to, from, next) {
  //   // const route = useRoute()
  //   const transId = to.params.transactionId
  //   // console.log({transId})
  //   next(vm => vm.loadTransactionType(transId))
  // }
}
</script>
